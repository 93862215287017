import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root' 
})
export class PatientService {
  private httpHeaders = new HttpHeaders() 
  //.set('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept, Authorization')
    .set('Authorization','bearer '+localStorage.getItem("MedlUToken"))

  private environment;
  private url;

  constructor(private httpClient: HttpClient) {

    this.environment = environment;
    this.url = this.environment.url;
  }

  // getMemberInfo(username:string){
  //   return this.httpClient.get<any>(`${this.url}/api/Member/GetMemberByUsername?username=${username}`,{headers:this.httpHeaders} )
  // }

  getMemberPatientInfoByUsername(username:string){
    return this.httpClient.get<any>(`${this.url}/api/member/GetMemberPatientInfoByUsername?username=${username}`,{headers:this.httpHeaders} )
  }


  getMembersByUserName(username: string) { 
    return this.httpClient.get<any>(`${this.url}/api/Member/GetMembersByUsername?username=${username}` ,{headers:this.httpHeaders})
  }

  GetContactByMemberId(memberId:number){
    return this.httpClient.get<any>(`${this.url}/api/Member/GetContactByMemberId?memberId=${memberId}` ,{headers:this.httpHeaders})
  }
  
}
