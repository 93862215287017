import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { concat } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/services/login.service';
import { PatientService } from 'src/app/services/patient.service';
import { VisitService } from 'src/app/services/visit.service';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss']
})
export class VisitsComponent implements OnInit {

  patientVisits: any = []
  labTestArr: any = []
  labActionsList: any = []
  patientId: number = 0;
  patientName: string = '';
  display = false;
  email: any = '';
  labOrderId: number = 0;
  showLabTest = false;
  preVisits: any=[];
  showPreVisits=false;
  showPreVisitsChart=false;

  basicData: any;

 

  basicOptions: any;
  isSingleLine!: boolean;
  preVisitsChart: any=[];
  labTestName: string='';
  constructor(private visitService: VisitService, private loginService: LoginService, private patientService: PatientService, private route: ActivatedRoute) {

    this.route.params.subscribe(params => {
      let patientid = Number(params['id']);
      this.patientId = patientid
    })
    this.route.queryParams.subscribe(params => {
      this.patientName = params['patientName']
    })
  }

  ngOnInit(): void {
    this.loginService.username.subscribe(u => {
      this.email = u
    })

    this.getLabsOrdersByMemberId(this.patientId)
    // this.getContactByMemberId(this.patientId)
    //  this.labTestArr=[{code:'NHDLC',name:'Non-HDL Cholesterol,Serum',date:'27/04/2022' },
    //   {code:'TSH',name:'Thyroid Stimulating Hormone (TSH), Serum',date:'27/04/2022'},
    //   {code:'ZIN',name:'Zinc, Serum',date:'27/04/2022'}]

    this.labActionsList = [
      { label: 'View results', value: 1 },
      { label: 'View previous Results', value: 2 },
      { label: 'lab wise', value: 3 }
    ]

  }
  getContactByMemberId(memberId: number) {
   
    this.patientService.GetContactByMemberId(memberId).subscribe(res => {
      if (res) {
 
      }
    })
  }

  selectAction(event: any, labTestId: number) {

    if (event.value == 1) {
      let labtestid=Number(labTestId)
      this.visitService.GetOnlineLabResults(this.labOrderId, this.patientName, labtestid).subscribe(res => {
        if (res) {
          window.location.href = res
        }
      })
    }
    if (event.value == 2) {
     
      this.showPreVisits=false
      this.showPreVisitsChart=false
      this.visitService.getPreviousResults(this.labOrderId,labTestId,this.patientId).subscribe(res => {
        if (res) {
          this.isSingleLine=res[0].IsSingleLine
          if ( this.isSingleLine==false) {
            this.showPreVisits=true
            this.preVisits=res
          }
          else{
            this.showPreVisitsChart=true
            this.preVisitsChart=res
            this.labTestName=res[0].LabTestName
            this.basicData = {
              labels: this.preVisitsChart.map((stat: { RequestDate: any; }) => stat.RequestDate),
              datasets: [
                  {
                      label: this.labTestName,
                      data: this.preVisitsChart.map((stat: { Value: any; }) => stat.Value),
                      
                      borderColor: '#42A5F5',
                      tension: .4
                  },
              ],
              options: {
                scales: {
                  yAxes: [{
                    ticks: {
                      min: 0,
                      max: 140
                    }
                  }]
                }
              }
          };
          }
          // this.showPreVisits=true
          // this.showPreVisitsChart=true
          

        }
      })
    }
  }
  Search(id: number, visitNo: any) {
    this.visitService.getLabsOrdersByMemberIdWithVisitNo(id, visitNo,'','','').subscribe(res => {
      if (res) {
        this.patientVisits = res
      }
    })
  }

  getLabsOrdersByMemberId(id: number) {

    this.visitService.getLabsOrdersByMemberId(id).subscribe(res => {
      if (res) {
        this.patientVisits = res
      }
    })
  }

  // getLabTestsResultsForReferral(labId:number){
  //   this.visitService.getLabTestsResultsForReferral(labId,this.patientName).subscribe(res=>{
  //     if (res) {
  //       window.location.href=res[0]
  //     }
  //   })
  // }

  getOnlineLabResultsByOrderId(labId: number) {
     
    this.visitService.getOnlineLabResults(labId, this.patientName).subscribe(res => {
      if (res) {
        window.location.href = res
      }
    })
  }

  showDialog(id: number) {
    this.display = true
    this.labOrderId = id

  }

  sendEmail() {
    this.visitService.sendLabResultByNotificationEmail(this.labOrderId, this.patientName).subscribe(res => {
      if (res) {
        return
      }
    })
  }

  show(id: any) {
    this.showLabTest = true
    this.labOrderId = id
    this.visitService.getLabTestByLabOrerId(id).subscribe(res => {
      if (res) {
        this.labTestArr = res
      }
    })
  }

  viewResult(labOrderd: number,labTestId: any){
    debugger
    let labtestid=Number(labTestId)
    this.visitService.GetOnlineLabResults(labOrderd, this.patientName, labtestid).subscribe(res => {
      if (res) {
        window.location.href = res
      }
    })
  }
}
