import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as Highcharts from 'highcharts';
import HighchartsMore from "highcharts/highcharts-more";
import item from 'highcharts/modules/item-series';
import { VisitService } from '../services/visit.service';
import { DOCUMENT } from '@angular/common';
HighchartsMore(Highcharts);

@Component({
  selector: 'app-interactive-result',
  templateUrl: './interactive-result.component.html',
  styleUrls: ['./interactive-result.component.scss']
})
export class InteractiveResultComponent implements OnInit {

  //#region Properties

  highcharts: typeof Highcharts = Highcharts;

  loggedUser: any;
  date: Date = new Date();

  gaugesOptions: any;
  barOptions: any;
  perviousOptions: any;

  visitId: number = -1;
  labTestId: number = -1;
  labOrderId: number = -1;

  labResultsForInteractiveLab: any = null;

  unitList: any[] = [];
  selectedUnit: any = null;

  showSpeedMeter: boolean = false;
  showLinearChart: boolean = false;
  showPreviousResult: boolean = false;

  chartList: Chart[] = [];

  groupList: any[] = [];
  packageList: any[] = [];
  normalList: any[] = [];

  visitInfoForInteractiveResult: any = null;

  isOrderLevel: boolean = false;
  visitInteractiveLabResults: any = null;

  abnormalResults: any = null;

  langList: any[] = [];
  //abnromalList: any[] = [];

  lang: any = null //en: English by defualt ar: arabic.
  selectedLang: string = "en";

  yesNoList: any[] = [];
  previousResult: any = null;

  showChartDescription: boolean = false;
  hovered: boolean = false;

  //#endregion
  constructor(private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private visitService: VisitService,
    @Inject(DOCUMENT) private document: Document) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.titleService.setTitle("Interactive Result");
      let v: string = params['v'];
      if (v != null && v != '') {
        let ids = v.split("-");
        this.visitId = parseInt(ids[0]); //visitID
        this.labOrderId = parseInt(ids[1]); //labOrderID
        if (ids[2] && ids[2] != null) {
          this.isOrderLevel = false;
          this.labTestId = parseInt(ids[2]); //labTestID
        }
        else {
          this.isOrderLevel = true;
          this.labTestId = -1;
        }
      }
    });
  }

  ngOnInit(): void {
    this.FillLangList();
    this.FillYesNoList();
    this.FillVisitInfoForInteractiveResult();
    this.FillUnitList();

    if (!this.isOrderLevel)
      this.FillLabResultsForInteractiveLab();
    else
      this.FillVisitInteractiveLabResults();
  }

  //#region Events

  FillUnitList() {
    this.unitList = [
      { id: 1, name: "Conventional" },
      { id: 2, name: "SI" }
    ];

    this.selectedUnit = this.unitList[0];
  }

  FillLangList() {
    this.langList = [
      { value: "en", name: "English", name2: "English" },
      { value: "ar", name: "العربية", name2: "العربية" }
    ];

    this.lang = this.langList[0];
    this.selectedLang = this.langList[0].value;
    this.document.documentElement.lang = this.selectedLang;
  }

  onChangeLanguage() {
    this.selectedLang = this.lang.value;
    this.document.documentElement.lang = this.selectedLang;
    document.getElementsByTagName('body')[0].style.setProperty('direction', this.selectedLang == 'en' ? 'ltr' : 'rtl', 'important');

    if (!this.isOrderLevel)
      this.FillLabResultsForInteractiveLab(this.abnormalResults.value, this.lang.value, this.previousResult.value);
    else
      this.FillVisitInteractiveLabResults(this.abnormalResults.value, this.lang.value, this.previousResult.value);
  }

  FillYesNoList() {
    this.yesNoList = [
      { value: false, name: "No", name2: "لا" },
      { value: true, name: "Yes", name2: "نعم" },
    ];

    this.abnormalResults = this.yesNoList[0];
    this.previousResult = this.yesNoList[1];
  }

  onChangeAbnormalResults(isAbnormalResults: boolean) {
    if (!this.isOrderLevel)
      this.FillLabResultsForInteractiveLab(isAbnormalResults, this.selectedLang, this.previousResult.value);
    else
      this.FillVisitInteractiveLabResults(isAbnormalResults, this.selectedLang, this.previousResult.value);
  }

  onChangePreviousResult(loadPreviousResult: boolean) {
    if (!this.isOrderLevel)
      this.FillLabResultsForInteractiveLab(this.abnormalResults.value, this.selectedLang, loadPreviousResult);
    else
      this.FillVisitInteractiveLabResults(this.abnormalResults.value, this.selectedLang, loadPreviousResult);
  }

  onUnitChange() {
    if (!this.isOrderLevel)
      this.FillLabResultsForInteractiveLab(this.abnormalResults.value, this.selectedLang, this.previousResult.value);
    else
      this.FillVisitInteractiveLabResults(this.abnormalResults.value, this.selectedLang, this.previousResult.value);
  }

  setResultStyle(color) {
    //let border = `3px solid ${color != null ? color : '#A020F0'}`
    //let style = { 'border': border, 'border-radius': '15px', 'padding': '10px' };
    let style = { 'background-color': color != null && color != "" ? color : '#A020F0' };
    return style;
  }

  setIconResultStyle(color) {
    return { 'color': color != null && color != "" ? color : '#A020F0' };
  }

  //#endregion

  //#region Methods

  async FillLabResultsForInteractiveLab(showAbnormalResultsOnly: boolean = false, lang: string = 'en', loadPreviousResult: boolean = true) {
    let isUnit1 = this.selectedUnit.id == 1;

    this.labResultsForInteractiveLab = null;
    let chartList = [];
    this.normalList = [];
    await this.visitService.GetLabResultsForInteractiveLab(this.labTestId, this.labOrderId, this.selectedUnit.id, showAbnormalResultsOnly).toPromise().then(async res => {
      if (res.Data) {
        this.labResultsForInteractiveLab = res.Data;

        // Generate Chart List
        chartList = [];
        let normalView = new NormalView();
        normalView.labTests = [];

        let attributes: any[] = this.labResultsForInteractiveLab.formAttributes;

        let perviousOptions = null;
        if (this.labResultsForInteractiveLab.labTestTypeValue != 2 && loadPreviousResult) { // Not Multiline
          let unit = isUnit1 ? attributes[0].attributeDataTestConvUnit : attributes[0].attributeDataTestSIUnit;
          perviousOptions = await this.FillPreviousResultsForInteractiveLab(unit, lang);
        }

        let interactiveTestDisplayName = lang == "en" ? this.labResultsForInteractiveLab.interactiveTestDisplayName : this.labResultsForInteractiveLab.interactiveTestDisplayName2;
        let interactiveTestDescription = lang == "en" ? this.labResultsForInteractiveLab.interactiveTestDescription : this.labResultsForInteractiveLab.interactiveTestDescription2;

        let labTest = new LabTest();
        labTest.name = interactiveTestDisplayName;
        labTest.description = interactiveTestDescription;
        labTest.labTestType = this.labResultsForInteractiveLab.labTestTypeValue;

        for (let attribute of Array.from(attributes)) {
          let chart = new Chart();
          let interactiveAttributeDisplayName = lang == "en" ? attribute.interactiveAttributeDisplayName : attribute.interactiveAttributeDisplayName2;
          let interactiveAttributeDescription = lang == "en" ? attribute.interactiveAttributeDescription : attribute.interactiveAttributeDescription2;

          chart.name = interactiveAttributeDisplayName;
          chart.description = interactiveAttributeDescription;
          chart.showDescription = false;
          chart.hoverDescription = false;
          chart.showSpeedMeter = this.labResultsForInteractiveLab.interactiveTestChartTypeId == 1; // Speed Meter
          chart.showLinearChart = this.labResultsForInteractiveLab.interactiveTestChartTypeId == 2; // Linear Chart
          chart.showPreviousResult = perviousOptions != null && this.labResultsForInteractiveLab.labTestTypeValue != 2; // Not Multiline

          if (chart.showSpeedMeter) {
            chart.options = this.GenerateGaugesChart(attribute, lang);
          }

          if (chart.showLinearChart) {
            chart.options = this.GenerateBarChart(attribute, lang);
          }

          chart.ranges = attribute?.ranges;
          chart.perviousOptions = chart.showPreviousResult ? perviousOptions : null;

          let result = new Result();
          let value = isUnit1 ? attribute.attributeDataTestConvResult : attribute.attributeDataTestSIResult;
          let resultType = isUnit1 ? attribute.ResultTypeConv : attribute.ResultTypeSI;
          let resultTypeAR = isUnit1 ? attribute.ResultTypeConvAR : attribute.ResultTypeSIAR;

          result.value = isUnit1 ? `${value} ${attribute.attributeDataTestConvUnit} (${attribute?.resultDate})` : `${value} ${attribute.attributeDataTestSIUnit} (${attribute?.resultDate})`;
          result.color = attribute.attributeDataTestResultColor;
          result.result = lang == "en" ? resultType : resultTypeAR;
          result.description = lang == "en" ? attribute.referenceResultDescription : attribute.referenceResultDescription2;

          chart.result = result;
          chartList.push(chart);
        }

        labTest.chartList = chartList;
        normalView.labTests.push(labTest);
        this.normalList.push(normalView);
      }
    });
  }

  async FillVisitInfoForInteractiveResult() {
    this.visitInfoForInteractiveResult = null;
    await this.visitService.GetVisitInfoForInteractiveResult(this.labOrderId).toPromise().then(res => {
      if (res) {
        this.visitInfoForInteractiveResult = res;
      }
    });
  }

  async FillVisitInteractiveLabResults(showAbnormalResultsOnly: boolean = false, lang: string = 'en', loadPreviousResult: boolean = true) {
    let isUnit1 = this.selectedUnit.id == 1;
    this.visitInteractiveLabResults = null;
    let chartList: Chart[] = [];
    this.groupList = [];
    this.packageList = [];
    this.normalList = [];
    await this.visitService.GetVisitInteractiveLabResults(this.labOrderId, this.selectedUnit.id, showAbnormalResultsOnly).toPromise().then(async res => {
      if (res.Data) {
        this.visitInteractiveLabResults = res.Data[0];
        //Generate Chart List
        let normalView = new NormalView();
        normalView.labTests = [];
        let interActiveTestNormals: any[] = this.visitInteractiveLabResults?.interActiveTestNormalsObj;
        for (let interActiveTestNormal of Array.from(interActiveTestNormals)) {
          chartList = [];
          let attributes: any[] = interActiveTestNormal.formAttributes;
          let interactiveTestDisplayName = lang == "en" ? interActiveTestNormal.interactiveTestDisplayName : interActiveTestNormal.interactiveTestDisplayName2;
          let interactiveTestDescription = lang == "en" ? interActiveTestNormal.interactiveTestDescription : interActiveTestNormal.interactiveTestDescription2;

          let labTest = new LabTest();
          labTest.name = interactiveTestDisplayName;
          labTest.description = interactiveTestDescription;
          labTest.labTestType = interActiveTestNormal.labTestTypeValue;

          let perviousOptions = null;
          if (interActiveTestNormal.labTestTypeValue != 2 && loadPreviousResult) {
            let unit = isUnit1 ? attributes[0].attributeDataTestConvUnit : attributes[0].attributeDataTestSIUnit;
            perviousOptions = await this.FillPreviousResultsForInteractiveLab(unit, lang, interActiveTestNormal.labTestId);
          }

          for (let attribute of Array.from(attributes)) {
            let chart = new Chart();
            let interactiveAttributeDisplayName = lang == "en" ? attribute.interactiveAttributeDisplayName : attribute.interactiveAttributeDisplayName2;
            let interactiveAttributeDescription = lang == "en" ? attribute.interactiveAttributeDescription : attribute.interactiveAttributeDescription2;
            chart.name = interactiveAttributeDisplayName;
            chart.description = interactiveAttributeDescription;
            chart.showDescription = false;
            chart.hoverDescription = false;
            chart.showSpeedMeter = interActiveTestNormal.interactiveTestChartTypeId == 1;
            chart.showLinearChart = interActiveTestNormal.interactiveTestChartTypeId == 2;
            chart.showPreviousResult = perviousOptions != null && interActiveTestNormal.labTestTypeValue != 2;

            if (chart.showSpeedMeter) {
              chart.options = this.GenerateGaugesChart(attribute, lang);
            }

            if (chart.showLinearChart) {
              chart.options = this.GenerateBarChart(attribute, lang);
            }

            chart.ranges = attribute?.ranges;
            chart.perviousOptions = chart.showPreviousResult ? perviousOptions : null;

            let result = new Result();
            let value = isUnit1 ? attribute.attributeDataTestConvResult : attribute.attributeDataTestSIResult;
            result.value = isUnit1 ? `${value} ${attribute.attributeDataTestConvUnit} (${attribute?.resultDate})` : `${value} ${attribute.attributeDataTestSIUnit} (${attribute?.resultDate})`;
            result.color = attribute.attributeDataTestResultColor;
            result.result = lang == "en" ? attribute.ResultTypeConv : attribute.ResultTypeSIAR;
            result.description = lang == "en" ? attribute.referenceResultDescription : attribute.referenceResultDescription2;

            chart.result = result;
            chartList.push(chart);
          }

          labTest.chartList = chartList;
          normalView.labTests.push(labTest);
        }
        this.normalList.push(normalView);

        // Generate Package List
        let interActiveTestPackages: any[] = this.visitInteractiveLabResults?.interActiveTestPackagesObj;
        for (let interActiveTestPackage of Array.from(interActiveTestPackages)) {
          let packageView = new PackageView();
          packageView.description = lang == "en" ? interActiveTestPackage.interactiveTestGroupDescription : interActiveTestPackage.InteractiveTestGroupDescription2;
          packageView.image = interActiveTestPackage.interactiveTestGroupImage;
          packageView.serviceName = lang == "en" ? interActiveTestPackage.serviceName : interActiveTestPackage.serviceName2;

          let labTests: any[] = interActiveTestPackage.labTest;
          packageView.labTests = [];

          for (let test of Array.from(labTests)) {
            chartList = [];

            let attributes: any[] = test.formAttributes;
            let interactiveTestDisplayName = lang == "en" ? test.interactiveTestDisplayName : test.interactiveTestDisplayName2;
            let interactiveTestDescription = lang == "en" ? test.interactiveTestDescription : test.interactiveTestDescription2;

            let labTest = new LabTest();
            labTest.name = interactiveTestDisplayName;
            labTest.description = interactiveTestDescription;
            labTest.labTestType = test.labTestTypeValue;

            for (let attribute of Array.from(attributes)) {
              let chart = new Chart();
              let interactiveAttributeDisplayName = lang == "en" ? attribute.interactiveAttributeDisplayName : attribute.interactiveAttributeDisplayName2;
              let interactiveAttributeDescription = lang == "en" ? attribute.interactiveAttributeDescription : attribute.interactiveAttributeDescription2;
              chart.name = interactiveAttributeDisplayName;
              chart.description = interactiveAttributeDescription;
              chart.showDescription = false;
              chart.hoverDescription = false;
              chart.showSpeedMeter = test.interactiveTestChartTypeId == 1; // Speed Meter
              chart.showLinearChart = test.interactiveTestChartTypeId == 2; // Linear Chart

              if (chart.showSpeedMeter) {
                chart.options = this.GenerateGaugesChart(attribute, lang);
              }
              if (chart.showLinearChart) {
                chart.options = this.GenerateBarChart(attribute, lang);
              }

              chart.ranges = attribute?.ranges;

              let result = new Result();
              let value = isUnit1 ? attribute.attributeDataTestConvResult : attribute.attributeDataTestSIResult;
              let resultType = isUnit1 ? attribute.ResultTypeConv : attribute.ResultTypeSI;
              let resultTypeAR = isUnit1 ? attribute.ResultTypeConvAR : attribute.ResultTypeSIAR;
              result.value = isUnit1 ? `${value} ${attribute.attributeDataTestConvUnit} (${attribute?.resultDate})` : `${value} ${attribute.attributeDataTestSIUnit} (${attribute?.resultDate})`;
              result.color = attribute.attributeDataTestResultColor;
              result.result = lang == "en" ? resultType : resultTypeAR;
              result.description = lang == "en" ? attribute.referenceResultDescription : attribute.referenceResultDescription2;

              chart.result = result;
              chartList.push(chart);
            }

            labTest.chartList = chartList;
            packageView.labTests.push(labTest);
          }

          this.packageList.push(packageView);
        }

        // Generate Group List
        let interActiveTestGroups: any[] = this.visitInteractiveLabResults?.interActiveTestGroupsObj;
        for (let interActiveTestGroup of Array.from(interActiveTestGroups)) {
          let groupView = new GroupView();
          groupView.description = lang == "en" ? interActiveTestGroup.interactiveTestGroupDescription : interActiveTestGroup.InteractiveTestGroupDescription2;
          groupView.image = interActiveTestGroup.interactiveTestGroupImage;
          groupView.serviceName = lang == "en" ? interActiveTestGroup.serviceName : interActiveTestGroup.serviceName2;

          let labTests: any[] = interActiveTestGroup.labTest;
          groupView.labTests = [];

          for (let test of Array.from(labTests)) {
            chartList = [];

            let attributes: any[] = test.formAttributes;
            let interactiveTestDisplayName = lang == "en" ? test.interactiveTestDisplayName : test.interactiveTestDisplayName2;
            let interactiveTestDescription = lang == "en" ? test.interactiveTestDescription : test.interactiveTestDescription2;

            let labTest = new LabTest();
            labTest.name = interactiveTestDisplayName;
            labTest.description = interactiveTestDescription;
            labTest.labTestType = test.labTestTypeValue;

            for (let attribute of Array.from(attributes)) {
              let chart = new Chart();
              let interactiveAttributeDisplayName = lang == "en" ? attribute.interactiveAttributeDisplayName : attribute.interactiveAttributeDisplayName2;
              let interactiveAttributeDescription = lang == "en" ? attribute.interactiveAttributeDescription : attribute.interactiveAttributeDescription2;
              chart.name = interactiveAttributeDisplayName;
              chart.description = interactiveAttributeDescription;
              chart.showDescription = false;
              chart.hoverDescription = false;
              chart.showSpeedMeter = test.interactiveTestChartTypeId == 1; // Speed Meter
              chart.showLinearChart = test.interactiveTestChartTypeId == 2; // Linear Chart

              if (chart.showSpeedMeter) {
                chart.options = this.GenerateGaugesChart(attribute, lang);
              }
              if (chart.showLinearChart) {
                chart.options = this.GenerateBarChart(attribute, lang);
              }

              chart.ranges = attribute?.ranges;

              let result = new Result();
              let value = isUnit1 ? attribute.attributeDataTestConvResult : attribute.attributeDataTestSIResult;
              let resultType = isUnit1 ? attribute.ResultTypeConv : attribute.ResultTypeSI;
              let resultTypeAR = isUnit1 ? attribute.ResultTypeConvAR : attribute.ResultTypeSIAR;
              result.value = isUnit1 ? `${value} ${attribute.attributeDataTestConvUnit} (${attribute?.resultDate})` : `${value} ${attribute.attributeDataTestSIUnit} (${attribute?.resultDate})`;
              result.color = attribute.attributeDataTestResultColor;
              result.result = lang == "en" ? resultType : resultTypeAR;
              result.description = lang == "en" ? attribute.referenceResultDescription : attribute.referenceResultDescription2;
              result.resultType = resultType;

              chart.result = result;
              chartList.push(chart);
            }

            labTest.chartList = chartList;
            groupView.labTests.push(labTest);
          }

          this.groupList.push(groupView);
        }
      }
    });
  }

  async FillPreviousResultsForInteractiveLab(unit: string, lang: string = 'en', labTestId = -1) {
    let previousResults: any[] = [];
    let perviousOptions: any = null;
    await this.visitService.GetPreviousResultsForInteractiveLab(this.labTestId > 0 ? this.labTestId : labTestId, this.labOrderId).toPromise().then(res => {
      if (res.Data && res.Data.length > 0) {
        //Generate Charts
        previousResults = res.Data;
        if (previousResults != null)
          perviousOptions = this.GenerateLineChart(previousResults, lang, unit);
      } else {
        perviousOptions = null;
      }
    });

    return perviousOptions;
  }


  //#endregion

  //#region Generate Charts

  GenerateGaugesChart(formAttribute, lang: string = "en") {
    let isUnit1 = this.selectedUnit.id == 1;

    let value = isUnit1 ? formAttribute.attributeDataTestConvResult : formAttribute.attributeDataTestSIResult;
    let unit = isUnit1 ? formAttribute.attributeDataTestConvUnit : formAttribute.attributeDataTestSIUnit;
    let resultSubtitleText = `${value} ${unit} (${formAttribute?.resultDate})`;

    let plotBands = [];
    let ranges: any[] = formAttribute.ranges;
    Array.from(ranges).forEach((
      item => {
        plotBands.push({
          from: isUnit1 ? item.startValueConv : item.startValueSI,
          to: isUnit1 ? item.endValueConv : item.endValueSI,
          color: item.referenceRangeRangeColor, //'#038c5a',
          thickness: 10,
          borderRadius: '50%'
        })
      }));

    this.gaugesOptions = {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '70%'
      },

      title: {
        text: "",//resultSubtitleText,//lang == "en" ? formAttribute.interactiveAttributeDisplayName : formAttribute.interactiveAttributeDisplayName2,
        align: 'left',
        style: {
          color: formAttribute.attributeDataTestResultColor,
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: null,//resultSubtitleText,
        align: 'left',
        style: {
          color: formAttribute.attributeDataTestResultColor,
          fontWeight: 'bold'
        }
      },
      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '75%'],
        size: '110%'
      },

      // the value axis
      yAxis: {
        min: isUnit1 ? formAttribute.minNormalRangeConv : formAttribute.minNormalRangeSI,
        max: isUnit1 ? formAttribute.maxNormalRangeConv : formAttribute.maxNormalRangeSI,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickColor: '#FFFFFF',
        tickLength: 1,
        tickWidth: 1.5,
        minorTickInterval: null,
        startOnTick: true,
        allowDecimals: true,
        labels: {
          format: '{value:.1f}',
          distance: 20,
          style: {
            fontSize: '14px',
            fontWeight: 'bold'
          }
        },
        lineWidth: 0,

        plotBands: plotBands
      },

      exporting: {
        enabled: false
      },

      credits: {
        enabled: false
      },

      series: [{
        name: 'Value',
        data: [parseFloat(value)],
        dataLabels: {
          format: '{y:.2f}',
          borderWidth: 0,
          color: '#333333',
          style: {
            fontSize: '16px'
          }
        },
        dial: {
          radius: '80%',
          backgroundColor: 'gray',
          baseWidth: 12,
          baseLength: '0%',
          rearLength: '0%'
        },
        pivot: {
          backgroundColor: 'gray',
          radius: 6
        },
        enableMouseTracking: false,
        showInLegend: false,
      }
      ],

      accessibility: {
        enabled: false
      }
    };

    return this.gaugesOptions;
  }

  GenerateBarChart(formAttribute, lang: string = "en") {
    let isUnit1 = this.selectedUnit.id == 1;

    let value = isUnit1 ? formAttribute.attributeDataTestConvResult : formAttribute.attributeDataTestSIResult
    let unit = isUnit1 ? formAttribute.attributeDataTestConvUnit : formAttribute.attributeDataTestSIUnit
    let resultSubtitleText = `${value} ${unit} (${formAttribute?.resultDate})`;

    let ranges: any[] = formAttribute.ranges.sort((a, b) => b.endValueConv - a.endValueConv);
    let lengthOfRanges = formAttribute.ranges.length;
    let series = [];
    Array.from(ranges).forEach((
      item => {
        let value = isUnit1 ? (series.length == (lengthOfRanges - 1)) ? parseFloat(item.endValueConv) + 1 : item.endValueConv - item.startValueConv :
          (series.length == (lengthOfRanges - 1)) ? parseFloat(item.endValueSI) + 1 : item.endValueSI - item.startValueSI;

        series.push({
          name: item.referenceRangeDisplayName,
          data: [value],
          color: item.referenceRangeRangeColor,
          enableMouseTracking: false,
          showInLegend: false,
        })
      }));

    //Set Reading Value.
    series.push({
      name: 'Reading',
      type: 'line',
      data: [parseFloat(value)],
      showInLegend: false,
      color: 'black',
      enableMouseTracking: false
    })

    this.barOptions = {
      chart: {
        type: 'bar',
        height: 90
      },
      title: {
        text: "",//resultSubtitleText, //lang == "en" ? formAttribute.interactiveAttributeDisplayName : formAttribute.interactiveAttributeDisplayName2,
        align: 'left',
        style: {
          color: formAttribute.attributeDataTestResultColor,
          fontWeight: 'bold'
        }
      },
      subtitle: {
        text: null, //resultSubtitleText,
        align: 'left',
        style: {
          color: formAttribute.attributeDataTestResultColor,
          fontWeight: 'bold'
        }
      },

      xAxis: {
        visible: false
      },
      yAxis: {
        visible: true,
        min: isUnit1 ? formAttribute.minNormalRangeConv : formAttribute.minNormalRangeSI,
        max: isUnit1 ? formAttribute.maxNormalRangeConv : formAttribute.maxNormalRangeSI,
        startOnTick: false,
        endOnTick: false,
        title: {
          text: ''
        },
        labels: {
          style: {
            fontSize: '13px',
            fontWeight: 'bold'
          }
        }
      },

      tooltip: {
        enabled: false,
        headerFormat: '',
        shared: true,
        valueDecimals: 2,
      },
      plotOptions: {
        bar: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
            format: '{point.percentage:.0f}%'
          },
        }
      },
      exporting: { enabled: false },
      credits: {
        enabled: false
      },

      series: series,

      accessibility: {
        enabled: false
      }
    };

    return this.barOptions;
  }

  GenerateLineChart(previousResults: any[], lang: string = "en", unit: string) {
    let isUnit1 = this.selectedUnit.id == 1;

    let categories: any[] = [];
    let data: any[][] = [];

    Array.from(previousResults).forEach((
      previousResult => {
        let value = isUnit1 ? previousResult.resultConv : previousResult.resultSI;
        let color = previousResult.ResultColor;

        categories.push(previousResult.resultDate);
        data.push([parseFloat(value), color]);
      }));

    this.perviousOptions = {
      title: {
        text: lang == "en" ? `Previous Result (${unit})` : `النتيجة السابقة (${unit})`
      },

      accessibility: {
        point: {
          valueDescriptionFormat: '{xDescription}{separator}{value} million(s)'
        }
      },

      exporting: {
        enabled: false
      },

      credits: {
        enabled: false
      },

      xAxis: {
        title: {
          text: ''
        },
        categories: categories,
        labels: {
          style: {
            fontSize: '13px',
            fontWeight: 'bold'
          }
        }
      },

      yAxis: {
        title: {
          text: ''
        },
        //max: 250,
        min: 0,
        labels: {
          style: {
            fontSize: '13px',
            fontWeight: 'bold'
          }
        }
      },
      tooltip: {
        enable: false,
        headerFormat: '',
        pointFormat: '{point.y}'
      },
      series: [{
        keys: ['y', 'color'],
        data: data,
        enableMouseTracking: false,
        showInLegend: false,
        color: '#3c4043',
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '13px'
          },
          formatter: function () {
            return this.y;
          }
        }
      }]
    };

    return this.perviousOptions;
  }

  //#endregion
}

export class Chart {
  options: any;
  ranges: any;
  name: string;
  description: string;
  showDescription: boolean = false;
  hoverDescription: boolean = false;
  showSpeedMeter: boolean = false;
  showLinearChart: boolean = false;
  showPreviousResult: boolean = false;
  perviousOptions: any;
  result: Result;
}

export class NormalView {
  labTests: LabTest[];
//  chartList: Chart[];
}

export class GroupView {
  description: string;
  image: string;
  serviceName: string;
  labTests: LabTest[];
}

export class PackageView {
  description: string;
  image: string;
  serviceName: string;
  labTests: LabTest[];
}

export class Result {
  color: string;
  value: string;
  result: string;
  //resultAR: string;
  description: string;
  resultType: string;
}

export class LabTest {
  name: string;
  description: string;
  labTestType: LabTestType;
  chartList: Chart[];
}

export enum LabTestType {
  Group = 1,
  Multiline = 2,
  SingleLine = 3,
}
