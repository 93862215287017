import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-print-grid',
  templateUrl: './print-grid.component.html',
  styleUrls: ['./print-grid.component.scss']
})
export class PrintGridComponent implements OnInit {
  @Input('selectedFileName') selectedFileName; //Name file.
  @Input('showAllButton') showAllButton = false; //Show All Button. 
  @Input('showExcel') showExcel = false; //Show Excel. 
  @Input('showPDF') showPDF = false; //Show PDF. 

  //PDF
  @Input('sectionID') sectionID; //section ID.

  today: Date = new Date;
  exportText: string = "Export";

  constructor() { }

  ngOnInit(): void {
  }

  delayGenerate() {
    setTimeout(() => {
      this.generatePDF();
    }, 1000); // Adjust delay as needed

  }

  async testGeneratePDF() {
    let randomName: string = null;
    let fileName: string = null;
    // Random name if not send file name.
    if (this.selectedFileName == null) {
      randomName = (Math.random() + 1).toString(36).substring(2);
    }
    else {
      fileName = this.selectedFileName + '-' + formatDate(this.today, 'dd-MM-yyyy', 'en-US').toString();
    }


    const chartElement = document.getElementById(this.sectionID); // Replace with your chart's container ID
    html2canvas(chartElement).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const doc = new jsPDF('p', 'mm', 'a4'); // A4 size paper
      const imgWidth = 190;
      const pageHeight = doc.internal.pageSize.height;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      doc.save(fileName);
    });
  }

  async generatePDF() {
    debugger
    let randomName: string = null;
    let fileName: string = null;
    // Random name if not send file name.
    if (this.selectedFileName == null) {
      randomName = (Math.random() + 1).toString(36).substring(2);
    }
    else {
      fileName = this.selectedFileName + '-' + formatDate(this.today, 'dd-MM-yyyy', 'en-US').toString();
    }

    const div = document.getElementById(this.sectionID);

    //use html2canvas
    html2canvas(div)
      .then((successRef) => {
        //var doc = new jsPDF('p', 'mm', 'a4');
        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: [successRef.width, successRef.height],
          compress: false
        });
        var img = successRef.toDataURL('image/png');

        // Add image Canvas to PDF
        //const bufferX = 10;
        //const bufferY = 1;
        //const imgProps = (<any>doc).getImageProperties(img);
        //const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        //const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(
          img,
          'PNG',
          0,
          0,
          successRef.width,
          successRef.height,
          undefined,
          'FAST' //"NONE" | "FAST" | "MEDIUM" | "SLOW"
        );

        return doc;
      })
      .then((doc) =>
        doc.output('dataurlnewwindow')
        //doc.save(this.selectedFileName != null ? fileName : randomName)
      );
  }
}
