<section class="container">
  <div class="col-md-12">
    <div class="kt-portlet">
      <div id="highcharts">
        <div class="report-header-img">
          <img src="assets/images/medlab-header2.png" />
        </div>
        <div class="report-header">
          <div class="row">
            <div class="col-6">
              <div class="patient-card-details">
                <div class="col-md-3">
                  <span *ngIf="selectedLang == 'en'">Patient Name </span>
                  <span *ngIf="selectedLang == 'ar'">اسم المريض</span>
                </div>
                <div class="col-md-9 font-weight-bold">{{selectedLang == 'en' ? visitInfoForInteractiveResult?.FullName : visitInfoForInteractiveResult?.FullName2 || '-'}}</div>
              </div>
              <div class="patient-card-details">
                <div class="col-md-3">

                  <span *ngIf="selectedLang == 'en'">File No. </span>
                  <span *ngIf="selectedLang == 'ar'">رقم الملف</span>
                </div>
                <div class="col-md-9 font-weight-bold">{{visitInfoForInteractiveResult?.FileNo || '-'}}</div>
              </div>
              <div class="patient-card-details">
                <div class="col-md-3">
                  <span *ngIf="selectedLang == 'en'">Visit No.</span>
                  <span *ngIf="selectedLang == 'ar'">رقم الزيارة</span>
                </div>
                <div class="col-md-9 font-weight-bold">{{visitInfoForInteractiveResult?.VisitNo || '-'}}</div>
              </div>
              <div class="patient-card-details">
                <div class="col-md-3">
                  <span *ngIf="selectedLang == 'en'">Consultant</span>
                  <span *ngIf="selectedLang == 'ar'">الطبيب</span>
                </div>
                <div class="col-md-9 font-weight-bold">{{visitInfoForInteractiveResult?.Consultant || '-'}}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="patient-card-details">
                <div class="col-md-3">
                  <span *ngIf="selectedLang == 'en'">Age</span>
                  <span *ngIf="selectedLang == 'ar'">العمر</span>
                </div>
                <div class="col-md-9 font-weight-bold">{{visitInfoForInteractiveResult?.Age || '-'}}</div>
              </div>
              <div class="patient-card-details">
                <div class="col-md-3">
                  <span *ngIf="selectedLang == 'en'">Gender </span>
                  <span *ngIf="selectedLang == 'ar'">الجنس</span>
                </div>
                <div class="col-md-9 font-weight-bold" *ngIf="selectedLang == 'en'">{{visitInfoForInteractiveResult?.Gender || '-'}}</div>
                <div class="col-md-9 font-weight-bold" *ngIf="selectedLang == 'ar'">{{visitInfoForInteractiveResult?.GenderAR || '-'}}</div>
              </div>
              <div class="patient-card-details">
                <div class="col-md-3">
                  <span *ngIf="selectedLang == 'en'">External No.</span>
                  <span *ngIf="selectedLang == 'ar'">الرقم خارجي</span>
                </div>
                <div class="col-md-9 font-weight-bold">{{visitInfoForInteractiveResult?.ExternalNo || '-'}}</div>
              </div>
              <div class="patient-card-details">
                <div class="col-md-3">
                  <span *ngIf="selectedLang == 'en'">Sample Date</span>
                  <span *ngIf="selectedLang == 'ar'">تاريخ الفحص</span>
                </div>
                <div class="col-md-9 font-weight-bold">{{visitInfoForInteractiveResult?.SampleDate || '-'}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="report-body">
          <div class="row pb-3 ml-3 actions">

            <div class="col-md-4 dv-lang">
              <label *ngIf="selectedLang == 'en'">Language</label>
              <label *ngIf="selectedLang == 'ar'">اللغة</label>
              <!--<p-dropdown [options]="langList" [(ngModel)]="lang" [optionLabel]="selectedLang == 'en' ? 'name' : 'name2'"
    [showClear]="false" inputId="id" class="form-control"
    [ngModelOptions]="{standalone: true}"
    (onChange)="onChangeLanguage()"></p-dropdown>-->

              <p-selectButton [options]="langList" [(ngModel)]="lang" [optionLabel]="selectedLang == 'en' ? 'name' : 'name2'"
                              (onChange)="onChangeLanguage()"></p-selectButton>

            </div>
            <div class="col-md-4 dv-abnormal">
              <label *ngIf="selectedLang == 'en'" for="unit">Abnormal Results Only</label>
              <label *ngIf="selectedLang == 'ar'" for="unit">نتائج غير طبيعية فقط</label>

              <p-selectButton [options]="yesNoList" [(ngModel)]="abnormalResults" [optionLabel]="selectedLang == 'en' ? 'name' : 'name2'"
                              (onChange)="onChangeAbnormalResults(abnormalResults.value)"></p-selectButton>
              <!--<p-inputSwitch [(ngModel)]="abnormalResults" (onChange)="onChangeAbnormalResults($event.checked)"></p-inputSwitch>-->
            </div>
            <div class="col-md-4 dv-previousResult">
              <label *ngIf="selectedLang == 'en'" for="unit">Show Previous Result</label>
              <label *ngIf="selectedLang == 'ar'" for="unit">إظهار النتائج السابقة</label>

              <p-selectButton [options]="yesNoList" [(ngModel)]="previousResult" [optionLabel]="selectedLang == 'en' ? 'name' : 'name2'"
                              (onChange)="onChangePreviousResult(previousResult.value)"></p-selectButton>
              <!--<p-inputSwitch [(ngModel)]="abnormalResults" (onChange)="onChangeAbnormalResults($event.checked)"></p-inputSwitch>-->
            </div>
            <div class="col-md-4 dv-unit" *ngIf="false">
              <label *ngIf="selectedLang == 'en'" for="unit">Unit</label>
              <label *ngIf="selectedLang == 'ar'" for="unit">الوحدة</label>
              <!--<p-dropdown [options]="unitList" [(ngModel)]="selectedUnit" optionLabel="name"
                [showClear]="false" inputId="id" class="form-control"
                [ngModelOptions]="{standalone: true}"
                (onChange)="onUnitChange()"
                #unit></p-dropdown>-->

              <p-selectButton [options]="unitList" [(ngModel)]="selectedUnit" optionLabel="name"
                              (onChange)="onUnitChange()"></p-selectButton>
            </div>
          </div>
          <div class="normal-charts" *ngIf="normalList.length > 0">
            <div *ngFor="let normal of normalList ;let i=index">
              <div *ngFor="let labTest of normal.labTests ;let i=index">
                <fieldset class="chart-info" *ngIf="labTest?.labTestType == 2 && labTest.chartList.length > 0">
                  <legend>
                    {{labTest?.name}}
                  </legend>
                  <div class="chart-description">
                    {{labTest?.description}}
                  </div>
                  <div *ngFor="let chart of labTest.chartList ;let i=index">
                    <div class="highcharts-bar" *ngIf="chart.showLinearChart">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                (mouseover)="chart.hoverDescription = true"
                                (mouseleave)="chart.hoverDescription = false"
                                (click)="chart.showDescription = !chart.showDescription"
                                [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                          <!--Vitamin D (25-hydroxycholecalciferol) is a hormone-like substance essential for calcium absorption, bone health, and immune function. Serum levels measure the amount of Vitamin D circulating in the blood.-->
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block;"></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                          <!--A panic level result for Vitamin D (25-hydroxycholecalciferol), Serum indicates an extremely abnormal value, potentially leading to severe health issues such as hypercalcemia or kidney dysfunction. Immediate medical intervention is necessary.-->
                        </div>

                        <div class="pervious-chart pervious" *ngIf="chart.showPreviousResult">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.perviousOptions"
                                            style="width: 40%; display: block;"></highcharts-chart>
                        </div>
                      </fieldset>
                    </div>
                    <div class="highcharts-gauges" *ngIf="chart.showSpeedMeter">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                (mouseover)="chart.hoverDescription = true"
                                (mouseleave)="chart.hoverDescription = false"
                                (click)="chart.showDescription = !chart.showDescription"
                                [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block; "></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                        </div>

                        <div class="pervious-chart pervious" *ngIf="chart.showPreviousResult">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.perviousOptions"
                                            style="width: 40%; display: block;"></highcharts-chart>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </fieldset>
                <div *ngIf="labTest?.labTestType == 3 && labTest.chartList.length > 0">
                  <div *ngFor="let chart of labTest.chartList ;let i=index">
                    <div class="highcharts-bar" *ngIf="chart.showLinearChart">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                 (mouseover)="chart.hoverDescription = true"
                                 (mouseleave)="chart.hoverDescription = false"
                                 (click)="chart.showDescription = !chart.showDescription"
                                 [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                          <!--Vitamin D (25-hydroxycholecalciferol) is a hormone-like substance essential for calcium absorption, bone health, and immune function. Serum levels measure the amount of Vitamin D circulating in the blood.-->
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block;"></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                          <!--A panic level result for Vitamin D (25-hydroxycholecalciferol), Serum indicates an extremely abnormal value, potentially leading to severe health issues such as hypercalcemia or kidney dysfunction. Immediate medical intervention is necessary.-->
                        </div>

                        <div class="pervious-chart pervious" *ngIf="chart.showPreviousResult">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.perviousOptions"
                                            style="width: 40%; display: block;"></highcharts-chart>
                        </div>
                      </fieldset>
                    </div>
                    <div class="highcharts-gauges" *ngIf="chart.showSpeedMeter">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                (mouseover)="chart.hoverDescription = true"
                                (mouseleave)="chart.hoverDescription = false"
                                (click)="chart.showDescription = !chart.showDescription"
                                [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block; "></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                        </div>

                        <div class="pervious-chart pervious" *ngIf="chart.showPreviousResult">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.perviousOptions"
                                            style="width: 40%; display: block;"></highcharts-chart>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="groups" *ngIf="groupList.length > 0">
            <div *ngFor="let group of groupList ;let i=index">
              <div class="group-header">
                <img class="img" [src]="group.image" />
                <div class="title">
                  <div class="service-name">
                    {{group.serviceName}}
                  </div>
                  <div class="description">
                    {{group.description}}
                  </div>
                </div>
              </div>
              <div *ngFor="let labTest of group.labTests ;let i=index">
                <fieldset class="chart-info" *ngIf="labTest?.labTestType == 2 && labTest.chartList.length > 0">
                  <legend>
                    {{labTest?.name}}
                  </legend>
                  <div class="chart-description">
                    {{labTest?.description}}
                  </div>
                  <div class="group-body" *ngFor="let chart of labTest.chartList ;let i=index">
                    <div class="highcharts-bar" *ngIf="chart.showLinearChart">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                (mouseover)="chart.hoverDescription = true"
                                (mouseleave)="chart.hoverDescription = false"
                                (click)="chart.showDescription = !chart.showDescription"
                                [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block;"></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                        </div>
                      </fieldset>
                    </div>
                    <div class="highcharts-gauges" *ngIf="chart.showSpeedMeter">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                (mouseover)="chart.hoverDescription = true"
                                (mouseleave)="chart.hoverDescription = false"
                                (click)="chart.showDescription = !chart.showDescription"
                                [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block; "></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </fieldset>
                <div *ngIf="labTest?.labTestType == 3 && labTest.chartList.length > 0">
                  <div class="group-body" *ngFor="let chart of labTest.chartList ;let i=index">
                    <div class="highcharts-bar" *ngIf="chart.showLinearChart">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                (mouseover)="chart.hoverDescription = true"
                                (mouseleave)="chart.hoverDescription = false"
                                (click)="chart.showDescription = !chart.showDescription"
                                [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block;"></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                        </div>
                      </fieldset>
                    </div>
                    <div class="highcharts-gauges" *ngIf="chart.showSpeedMeter">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                (mouseover)="chart.hoverDescription = true"
                                (mouseleave)="chart.hoverDescription = false"
                                (click)="chart.showDescription = !chart.showDescription"
                                [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block; "></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="packages" *ngIf="packageList.length > 0">
            <div *ngFor="let package of packageList ;let i=index">
              <div class="package-header">
                <img class="img" [src]="package.image" />
                <div class="title">
                  <div class="service-name">
                    {{package.serviceName}}
                  </div>
                  <div class="description">
                    {{package.description}}
                  </div>
                </div>
              </div>
              <div *ngFor="let labTest of package.labTests ;let i=index">
                <fieldset class="chart-info" *ngIf="labTest?.labTestType == 2 && labTest.chartList.length > 0">
                  <legend>
                    {{labTest?.name}}
                  </legend>
                  <div class="chart-description">
                    {{labTest?.description}}
                  </div>
                  <div class="package-body" *ngFor="let chart of labTest.chartList ;let i=index">
                    <div class="highcharts-bar" *ngIf="chart.showLinearChart">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                (mouseover)="chart.hoverDescription = true"
                                (mouseleave)="chart.hoverDescription = false"
                                (click)="chart.showDescription = !chart.showDescription"
                                [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block;"></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                        </div>
                      </fieldset>
                    </div>
                    <div class="highcharts-gauges" *ngIf="chart.showSpeedMeter">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                (mouseover)="chart.hoverDescription = true"
                                (mouseleave)="chart.hoverDescription = false"
                                (click)="chart.showDescription = !chart.showDescription"
                                [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block; "></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </fieldset>
                <div *ngIf="labTest?.labTestType == 3 && labTest.chartList.length > 0">
                  <div class="package-body" *ngFor="let chart of labTest.chartList ;let i=index">
                    <div class="highcharts-bar" *ngIf="chart.showLinearChart">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                (mouseover)="chart.hoverDescription = true"
                                (mouseleave)="chart.hoverDescription = false"
                                (click)="chart.showDescription = !chart.showDescription"
                                [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block;"></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                        </div>
                      </fieldset>
                    </div>
                    <div class="highcharts-gauges" *ngIf="chart.showSpeedMeter">
                      <fieldset class="chart-info">
                        <legend>
                          {{chart.name}}
                        </legend>
                        <div class="value-result" [ngStyle]="setResultStyle(chart?.result.color)">
                          <span class="value">
                            <b>{{chart?.result.value}}</b>
                            <span *ngIf="chart?.result.result != null && chart?.result.result != ''"> | </span>
                            <b>{{chart?.result.result}}</b>
                          </span>
                          <span class="chart-description-icon"
                                (mouseover)="chart.hoverDescription = true"
                                (mouseleave)="chart.hoverDescription = false"
                                (click)="chart.showDescription = !chart.showDescription"
                                [pTooltip]="selectedLang == 'en' ? 'Show Description' : 'إظهار الوصف'" tooltipPosition="top" placeholder="Top">
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </div>
                        <div class="chart-description" *ngIf="chart.hoverDescription || chart.showDescription">
                          {{chart.description}}
                        </div>
                        <div class="chart">
                          <highcharts-chart [Highcharts]="highcharts" [options]="chart.options"
                                            style="width: 40%; display: block; "></highcharts-chart>
                          <div class="legend">
                            <div class="custom-legend-bar" *ngFor="let range of chart?.ranges ;let i=index">
                              <div class="legend-item" style="padding-bottom: 5px;">
                                <div class="custom-legend-item">
                                  <div class="legend-color" [ngStyle]="{'background-color': range.referenceRangeRangeColor }"></div>
                                  <div class="legend-name">{{selectedLang == 'en' ? range.referenceRangeDisplayName : range.referenceRangeDisplayName2}}</div>
                                </div>
                                <div>
                                  {{selectedUnit.id == 1 ? range.normalRangeRangeText : range.referenceRangeRangeTextSI}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="description-result">{{selectedLang == 'en' ? chart?.result.resultEN : chart?.result.resultAR}}</div>-->
                        <div class="result-description">
                          <div class="font-weight-bold" *ngIf="selectedLang == 'ar'">تفسير النتيجة</div>
                          <div class="font-weight-bold" *ngIf="selectedLang == 'en'">Result Description</div>
                          {{chart?.result.description}}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="report-footer-img">
        <img src="assets/images/footer.png">
      </div>
      <div class="report-footer">
        <div class="report-info">
          <span class="pl-3" *ngIf="selectedLang == 'en'">
            <b>Print DateTime: </b>{{date  | date:'dd/MM/yyyy hh:mm a'}}
          </span>
          <span class="pl-3" *ngIf="selectedLang == 'ar'">
            <b>تاريخ الطباعة: </b>{{date  | date:'dd/MM/yyyy hh:mm a'}}
          </span>
        </div>
        <button type="button" onclick="window.print();" class="btn-custom btn btn-sm btn-outline-secondary convert-pdf"
                pTooltip="Export PDF" tooltipPosition="top" *ngIf="selectedLang == 'en'">
          <i class="fa fa-file-pdf"></i>Export PDF
        </button>
        <button type="button" onclick="window.print();" class="btn-custom btn btn-sm btn-outline-secondary convert-pdf"
                pTooltip="Export PDF" tooltipPosition="top" *ngIf="selectedLang == 'ar'">
          <i class="fa fa-file-pdf"></i>تصدير الملف
        </button>
        <app-print-grid class="convert-pdf d-none" [sectionID]="'highcharts'" [showPDF]="true" [selectedFileName]="'high-charts'"></app-print-grid>
      </div>
    </div>
  </div>
</section>

