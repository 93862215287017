<div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor  home-page" id="kt_content">

  <!-- begin:: Content -->
  <div id="kt_header" class="kt-header kt-grid__item  kt-header--fixed">
    <h3>Online Results نتائج الفحوصات</h3>
    <div _ngcontent-pvt-c101="" class="header-content">
      <i _ngcontent-pvt-c101="" class="fas fa-sign-out-alt" (click)="SignOut()" title="Sign out"></i>
      <!--<i _ngcontent-pvt-c101="" class="fa fa-unlock-alt" ></i>-->
      <img src="./assets/medlabs-media/ChangePassword.png" (click)="ChangePassword()" title="Change password" />

    </div>
  </div>
  <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
    <!--<div class="row">
      <div class="col-md-12">
        <div class="kt-portlet__foot kt-portlet__foot--sm">
          <div class="kt-form__actions kt-form__actions--right">
            <i class="fas fa-sign-out-alt fa-2xl" style="font-size: 2rem;" (click)="SignOut()"></i>
            <i class="fa fa-unlock-alt" style="font-size: 2rem;" (click)="ChangePassword()"></i>
          </div>
        </div>
      </div>
    </div>-->
    <div class="row">
      <div class="col-md-12">
        <ngx-slick-carousel class="carousel "
                            #slickModal="slick-carousel"
                            [config]="slideConfig"
                            (init)="slickInit($event)"
                            (breakpoint)="breakpoint($event)"
                            (afterChange)="afterChange($event)"
                            (beforeChange)="beforeChange($event)">

          <div ngxSlickItem *ngFor="let patient of patients | orderBy:'Birthdate' : 'asc';let i=index" class="slide">
            <a href="javascript:;" (click)="getMemberInfoById(patient.Name,i)"
               class="kt-widget__username">
              <div class="{{i == selectedIndex ? 'col-xl-12 active-card' : 'col-xl-12'}}">
                <div class="kt-portlet kt-portlet--height-fluid system-parent">
                  <div class="kt-portlet__body kt-portlet__body--fit-y kt-mar gin-b-40">
                    <span class="kt-badge kt-badge--danger kt-badge--inline" *ngIf="i == 0">Owner</span>
                    <!--begin::Widget -->

                    <div class="kt-widget kt-widget--user-profile-4">
                      <div class="kt-widget__head">
                        <div class="kt-widget__content">
                          <div class="kt-widget__section">

                            <span class="kt-widget__username"> {{patient.Name}}</span>
                            <div class="kt-widget__button"> <span class="">File No. {{patient.FileNo}}</span></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--end::Widget -->
                  </div>
                </div>
              </div>
            </a>
          </div>


        </ngx-slick-carousel>
      </div>
    </div>
    <div class="row ">
      <div class="col-12">
        <!--begin:: Widgets/Applications/User/Profile3-->
        <div class="kt-portlet profile-area">
          <div class="kt-portlet__body ">
            <div class="kt-widget kt-widget--user-profile-3">
              <div class="kt-widget__top">
                <div class="kt-widget__media kt-hidden-">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                       width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                      <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                            fill="#000000" fill-rule="nonzero"></path>
                    </g>
                  </svg>
                </div>
                <div class="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-boldest kt-font-light kt-hidden">
                  JM
                </div>
                <div class="kt-widget__content">
                  <div class="kt-widget__head">
                    <a class="kt-widget__username" (click)="getLabsOrdersByMemberId(memberDetails.ID,memberDetails.EnglishName)">
                      {{memberDetails.EnglishName}}
                    </a>
                  </div>
                  <div class="kt-widget__subhead">
                    <a title="Gender">
                      <i class="fa fa-venus-mars"></i> <span>
                        {{
memberDetails.Gender==1
                                                ?'Male':'Female'
                        }}
                      </span>
                    </a>
                    <a title="Age">
                      <i class="flaticon2-calendar-3"></i> <span>
                        {{memberDetails.Age}}
                      </span>
                    </a>
                    <a *ngIf="memberDetails?.Program">
                      <i class="fas fa-laptop-medical"></i> <span>
                        <!--{{memberDetails.Program}}--> Loyalty Member
                      </span>
                    </a>
                    <a *ngIf="memberDetails?.Level">
                      <i class="flaticon2-graphic-1"></i> <span>
                        {{memberDetails.Level}}
                      </span>
                    </a>
                    <a *ngIf="memberDetails?.Points">
                      <i class="flaticon2-gift-1"></i> <span>
                        Points {{memberDetails.Points}}
                      </span>
                    </a>
                    <a *ngIf="memberDetails?.MembersCount"><i class="fas fa-users"></i><span>Members Count  {{memberDetails.MembersCount}}</span></a>
                  </div>

                  <!--<div class="input-group custom-card-search">
                    <input type="text" class="form-control" placeholder="Search visit" #input>
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="button" (click)="SearchVisit(this.patientId,input.value)">Search</button>
                    </div>
                  </div>-->
                </div>
              </div>
              <div class="kt-aside__brand-logo">
                <a href="index.html">
                  <img alt="Logo"
                       src="./assets/media/logos/rght-side-menu.png">
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--end:: Widgets/Applications/User/Profile3-->
      </div>
    </div>

    <div class="row">
      <div class="col-12">

        <div class="kt-portlet kt-portlet--head-sm search-area" data-ktportlet="true" id="kt_portlet_tools_2">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                Search Visits
              </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
              <div class="kt-portlet__head-group">
                <a (click)="toogle()" data-ktportlet-tool="toggle"
                   class="btn btn-label-brand btn-sm btn-icon btn-icon-sm"
                   aria-describedby="tooltip_6smvj7bmcp">
                  <i *ngIf="!iscollapse" class="la la-angle-down"></i>
                  <i *ngIf="iscollapse" class="la la-angle-up"></i>
                </a>
                <div class="tooltip tooltip-portlet tooltip bs-tooltip-top" role="tooltip" id="tooltip_fe08tq7i4b" aria-hidden="true" x-placement="top" style="position: absolute; will-change: transform; visibility: hidden; top: 0px; left: 0px; transform: translate3d(953px, -44px, 0px);">                            <div class="tooltip-arrow arrow" style="left: 34px;"></div>                            <div class="tooltip-inner">Collapse</div>                        </div>
              </div>
            </div>
          </div>

          <!--begin::Form-->
          <form class="kt-form" kt-hidden-height="195" *ngIf="iscollapse">
            <div class="kt-portlet__body">


              <div class="kt-portlet__content">
                <div class="row">

                  <div class="input-group">
                    <div class="col-md-3">
                      <label for="range"><span>Visit No. / </span> <span>رقم الزيارة </span></label>
                      <input type="text" class="form-control" #input>
                    </div>
                    <div class="col-md-3">
                      <label for="dateRange"><span>Visit Date / </span> <span>تاريخ الزيارة </span></label>
                      <p-calendar [(ngModel)]="rangeDates" selectionMode="range" class="form-control" inputId="dateRange"
                                  dateFormat="dd/mm/yy" [showIcon]="true" [readonlyInput]="true" [showButtonBar]="true"
                                  [ngModelOptions]="{standalone: true}" #visitDate></p-calendar>
                    </div>
                    <div class="col-md-3">
                      <label for="referral"><span>Referral / </span> <span>اسم الطبيب </span></label>
                      <input type="text" class="form-control" inputId="referral" #referral>
                    </div>
                    <div class="col-md-3">
                      <label for="status"><span>Status / </span> <span>الحالة </span></label>
                      <p-dropdown [options]="statuses" [(ngModel)]="selectedStatus" optionLabel="name" [showClear]="true" inputId="status" class="form-control"
                                  [ngModelOptions]="{standalone: true}" #status></p-dropdown>
                    </div>

                  </div>
                </div>
              </div>

            </div>
            <div class="kt-portlet__foot kt-portlet__foot--sm">
              <div class="kt-form__actions kt-form__actions--right">
                <button class="btn btn-primary" type="button" (click)="SearchVisit(this.patientId,input.value,referral.value)">Search</button>
              </div>

            </div>
          </form>
          <!--end::Form-->
        </div>
      </div>
    </div>

    <div class="row">

      <!--<div class="col-xl-8 col-lg-12 order-lg-3 order-xl-1">

        <div class="kt-portlet kt-portlet--height-fluid" *ngFor="let v of patientVisits;let i=index">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                Notifications
              </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
              <ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist">
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#kt_widget{{v.ID}}_tab1_content" role="tab" aria-selected="false">
                    {{v.VisitNo}}
                  </a>
                </li>

              </ul>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div class="tab-content">
              <div class="tab-pane" id="kt_widget{{v.ID}}_tab1_content" aria-expanded="true">
                <div class="kt-notification">


                  343434
                </div>
              </div>


            </div>
          </div>
        </div>


      </div>-->
      <div class="kt-portlet kt-portlet--height-fluid" *ngFor="let v of patientVisits;let i=index">

        <div class="kt-widget__subhead visit-info-des">
          <a href="javascript:void(0)" style="cursor: default;" onclick="return false;" disabled="disabled">
            <div><span>Visit No. </span> <span>رقم الزيارة </span></div>
            <span>{{v.VisitNo}}</span>
          </a>
          <a href="javascript:void(0)" style="cursor: default;" onclick="return false;">
            <div><span>Visit Date </span> <span>تاريخ الزيارة </span></div>
            <span>{{v.VisitDate | date:"dd/MM/yyyy"}}</span>
          </a>
          <a href="javascript:void(0)" style="cursor: default;" onclick="return false;">
            <div><span>Referral </span> <span>اسم الطبيب </span></div> <span>
              {{v.DoctorName ??'No Referral'}}
            </span>
          </a>



          <a href="javascript:;" class="label-status" onclick="return false;" *ngIf="v.Status != 'Finalized'">

            <span class="{{v.Status === 'In Progress' ? 'btn btn-bold btn-sm btn-font-sm btn-label-warning' : 'btn btn-bold btn-sm btn-font-sm  btn-label-success'}}" style="width: 131px;">{{v.Status}}</span>
          </a>
          <a href="javascript:;" (click)="getOnlineLabResultsByOrderId(v.ID)" class="label-status" style="cursor: pointer !important; "  *ngIf="v.Status === 'Finalized'" title="View PDF">

            <span class="btn btn-bold btn-sm btn-font-sm  btn-label-success" style="width: 131px; cursor: pointer !important; ">View Finalized Result</span>
          </a>
          <a class="btn btn-sm btn-label-danger btn-bold" data-toggle="tab" href="#kt_widget6_tab{{v.ID}}" role="tab" aria-selected="false" (click)="FillLabTests(v.ID)" [hidden]="showButton[v.ID]">
            <span> Show Individual Tests / عرض الفحوصات</span>
          </a>
          <a (click)="HideLabTests(v.ID)" [hidden]="!hideButton[v.ID]" class="btn btn-sm btn-label-danger btn-bold">
            <span> Hide Individual Tests / اخفاء الفحوصات</span>
          </a>
          <div class="actions-section">
            <a href="javascript:;" (click)="ViewInteractiveResultOrder(v.ID, v.VisitId)" class="btn btn-sm btn-icon btn-default btn-icon-sm"
               title="View Interactive Result" *ngIf="v.InterActiveResults && v.IsPublishedOnline && v.Status == 'Finalized'"><i class="fa fa-chart-bar"></i> </a>
            <a href="javascript:;" (click)="showSendResultPopup(v.ID)" class="btn btn-sm btn-icon btn-default btn-icon-sm"
               title="Send Results" *ngIf="v.Status == 'Finalized'"><i class="fas fa-paper-plane"></i> </a>
            <a href="javascript:;" *ngIf="false" (click)="getOnlineLabResultsByOrderId(v.ID)" class="btn btn-sm btn-icon btn-default btn-icon-sm"
               title="View PDF"> <i class="fas fa-file-pdf"></i> </a>

          </div>
        </div>



        <div class="kt-portlet__body">
          <div class="tab-content">
            <div [ngClass]="tab-pane" *ngIf="v.ID === selectedId" id="kt_widget6_tab{{v.ID}}" aria-expanded="false">

              <div *ngIf="labTests?.length > 0">

                <p-table #dt [value]="labTests" dataKey="id" styleClass="p-datatable-customers" [rowHover]="true"
                         [paginator]="false" [rows]="5" [totalRecords]="labTests?.length">
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width:15%;">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Code
                        </div>
                      </th>
                      <th style="width:40%;">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Name
                        </div>
                      </th>
                      <th style="width:15%;">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Date
                        </div>
                      </th>
                      <th style="width:8%;">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          View Result
                        </div>
                      </th>
                      <th style="width:8%;">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          View History
                        </div>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-labtest>
                    <tr class="p-selectable-row">
                      <td>
                        {{labtest.ServiceCode}}
                      </td>
                      <td>
                        {{labtest.ServiceName}}
                      </td>
                      <td>
                        {{labtest.RequestDate}}
                      </td>
                      <td>
                        <div class="dropdown dropdown-inline">
                          <a href="javascript:;"
                             class="btn btn-sm btn-icon btn-default btn-icon-sm d-none" title="lab wise"
                             data-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-flask"></i>
                          </a>
                        </div>

                        <a href="javascript:;" (click)="viewResults(labtest.ID)"
                           class="btn btn-sm btn-icon btn-default btn-icon-sm "
                           title="View Result">
                          <i class="fa fa-eye"></i>
                        </a>
                        <a href="javascript:;" *ngIf="labtest.InterActiveResults && labtest.IsPublishedOnline && v.Status == 'Finalized'" (click)="ViewInteractiveResult(labtest.ID, v.VisitId, v.ID)"
                           class="btn btn-sm btn-icon btn-default btn-icon-sm "
                           title="View Interactive Result">
                          <i class="fa fa-chart-bar"></i>
                        </a>
                      </td>
                      <td>
                        <a href="javascript:;" *ngIf="labtest.isSingleLineTest" (click)="viewPreviousResults(labtest)"
                           class="btn btn-sm btn-icon btn-default btn-icon-sm  "
                           title="View Previous Result">
                          <i class="fa fa-history"
                             aria-hidden="true"></i>
                        </a>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="4">No Data Found</td>
                    </tr>
                  </ng-template>
                </p-table>

              </div>
              <div *ngIf="showFinalizLable" class="note-">
                <span>* No finalized tests for this visit</span>
              </div>

              <div class="col-12 mb-3" *ngIf="showPreVisits==true">
                <p-fieldset legend="Previous Visits">
                  <div *ngIf="!preVisits[0].Message">
                    The Previous Visits For {{labTestName}} Are :<span *ngFor="let v of preVisits">
                      <a href="javascript:;" class="ancvst"
                         (click)="viewResult(v.LabOrderId,v.LabTestId)">{{v.VisitNumber}}</a>
                    </span>
                  </div>
                  <div *ngIf="preVisits[0].Message">
                    {{preVisits[0].Message}}
                  </div>
                </p-fieldset>
              </div>
            </div>
          </div>
        </div>

      </div>





      <!--<div class="mb-3" *ngIf="showPreVisitsChart==true">
        <p-fieldset legend="Previous Results">
          <div *ngIf="!preVisitsChart[0].Message">
            <p-chart type="line" [data]="basicData" [options]="basicOptions"></p-chart>
          </div>
          <div *ngIf="preVisitsChart[0].Message">
            {{preVisitsChart[0].Message}}
          </div>
        </p-fieldset>
      </div>-->

    </div>
    <!-- end:: Content -->
    <div class="row">
      <div class="col-12">
        <div class="kt-portlet kt-portlet--head-sm search-area">
          <div *ngIf="showNoDataFound" class="note-">
            <span>No Data Found </span>
          </div>
        </div>

      </div>
    </div>

  </div>

  <p-dialog [(visible)]="display" [style]="{width: '30vw'}" [modal]="true">
    <ng-template pTemplate="header">
      Send Results
    </ng-template>

    <ng-template pTemplate="body">
      <div class="form-group form-group-sm col-md-12">
        <label>Email</label>
        <input type="text" class="form-control form-control-sm" [value]="email" disabled>
      </div>


    </ng-template>
    <ng-template pTemplate="footer">
      <div class="kt-portlet__foot kt-portlet__foot--sm">
        <div class="kt-form__actions kt-form__actions--right">
          <button type="button" class="btn btn-primary" (click)="sendEmail()">Send Results</button>

        </div>
      </div>

    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="showChangePassword" [style]="{width: '30vw'}" [modal]="true">
    <ng-template pTemplate="header">
      Change Password
    </ng-template>
    <ng-template pTemplate="body">

      <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="showIncorrectPassword">
        <div class="alert-text">Incorrect Old Password.</div>
      </div>

      <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="showIncorrectConfirmPassword">
        <div class="alert-text">New Password & Confirm Password Are Not Matched.</div>
      </div>

      <form [formGroup]="ChangePasswordForm">
        <div class="row">
          <div class="form-group form-group-sm col-md-12">
            <label>Old Password</label>
            <input type="password" class="form-control form-control-sm" formControlName="ChangePasswordForm_oldPassword">
          </div>
          <div class="form-group form-group-sm col-md-12">
            <label>New Password</label>
            <input type="password" class="form-control form-control-sm" formControlName="ChangePasswordForm_newPassword">
          </div>
          <div class="form-group form-group-sm col-md-12">
            <label>Confirm Password</label>
            <input type="password" class="form-control form-control-sm" formControlName="ChangePasswordForm_confirmPassword">
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="kt-portlet__foot kt-portlet__foot--sm">
        <div class="kt-form__actions kt-form__actions--right">
          <button type="button" class="btn btn-primary" (click)="UpdatePassword()"
                  [disabled]="!ChangePasswordForm.valid">
            Change
          </button>

        </div>
      </div>

    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="showPreVisitsChart" [style]="{width: '90vw'}" [modal]="true">
    <ng-template pTemplate="header">
      Previous Results
    </ng-template>
    <ng-template pTemplate="body">

      <div class="kt-portlet kt-portlet--head-sm " data-ktportlet="true" id="kt_portlet_tools_2">


        <!--begin::Form-->
        <form class="kt-form">
          <div class="kt-portlet__body">


            <div class="kt-portlet__content" *ngIf="showPreVisitsChart==true">
              <p-fieldset legend="Previous Results">
                <div class="row" *ngIf="!preVisitsChart[0].Message">


                  <div class="form-group form-group-sm col-md-3" style="margin-top: 120px;">
                    <span style="font-size: 0.85rem; font-weight: 800" *ngIf="preVisitsChart[0]?.NormalRange">Reference Range :</span><div [innerHTML]="normalRange"></div>
                    <br />
                    <span style="font-size: 0.85rem; font-weight: 800" *ngIf="preVisitsChart[0]?.UnitName">Measurement Unit :</span> {{ unitName}}
                  </div>

                  <div class="form-group form-group-sm col-md-9">
                    <div class="highcharts-bar">
                      <div class="chart">
                        <highcharts-chart [Highcharts]="highcharts" [options]="barOptions" ></highcharts-chart>
                                         
                      </div>
                 
                      </div>
                      <!--<p-chart type="bar" [data]="basicData" [options]="basicOptions"></p-chart>-->

                    </div>
                  </div>

                <div *ngIf="preVisitsChart[0].Message">
                  {{preVisitsChart[0].Message}}
                </div>
              </p-fieldset>
            </div>
          </div>
        </form>
        <!--end::Form-->
      </div>
    </ng-template>

  </p-dialog>
  <!-- begin:: Footer -->
  <div class="kt-footer  kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
    <div class="kt-container  kt-container--fluid ">
      <div class="kt-footer__copyright">
        2020&nbsp;©&nbsp;<a href="https://www.eskadenia.com/" target="_blank"
                            class="kt-link">ESKADENIA Software</a>
      </div>
      <!--<div class="kt-footer__menu">
        <a href="http://keenthemes.com/metronic" target="_blank"
           class="kt-footer__menu-link kt-link">About</a> <a href="http://keenthemes.com/metronic"
                                                             target="_blank" class="kt-footer__menu-link kt-link">Team</a> <a href="http://keenthemes.com/metronic" target="_blank"
                                                                                                                              class="kt-footer__menu-link kt-link">Contact</a>
      </div>-->
    </div>
  </div>

</div>
