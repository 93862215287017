import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenHandlerService {

 
  constructor() {

   
  }

  isTokenExpired(): boolean {
   
    let token = localStorage.getItem(environment.storageToken);
    if (!token) return true;

    const date = this.getTokenExpiredate();
    if (date === undefined || date === null) return true;
    
    if (date > new Date().getTime())
      return false;
    else
      return true;
  }

  setTokenExpiredate(tokenSpaninMinute: number) {
 
    var expireDate = new Date();
    //var expireDate = new Date(currentDate.getTime() + (tokenSpaninMinute / 60));
    expireDate.setMinutes(expireDate.getMinutes() + (tokenSpaninMinute / 60));//(tokenSpaninMinute / 60)
    window.localStorage['tokenExpireDate'] = expireDate.getTime();
  }

  getTokenExpiredate() {
    if (localStorage['tokenExpireDate']) {
      return window.localStorage['tokenExpireDate'];
    }
    else {
      return null;

    }
  }
    

}
