import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintGridComponent } from './print-grid/print-grid.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations:[PrintGridComponent],
  imports: [
    CommonModule,
    TooltipModule
  ], exports: [
    PrintGridComponent
  ]
})
export class PrintgridModule { }
