import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LabResultsComponent } from './lab-results/lab-results.component';
import { LoginComponent } from './login/login.component';
import { VisitsComponent } from './visits/visits.component';
import { AuthGuard } from './services/auth.guard';
import { InteractiveResultComponent } from './interactive-result/interactive-result.component';

const routes: Routes = [
  { path: '', component: LoginComponent  },
  { path: 'login', component: LoginComponent },
  { path: 'interactive-result', component: InteractiveResultComponent, pathMatch: 'full' },
  { path: 'lab-results', component: LabResultsComponent, canActivate: [AuthGuard] },
  { path: 'visits', component: VisitsComponent, canActivate: [AuthGuard] },
  { path: 'visits/:id', component: VisitsComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
