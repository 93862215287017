import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { MessageService, ConfirmationService, ConfirmEventType } from 'primeng/api';

@Injectable({
  providedIn: "root"

})
export class CommonService {

  spinnerQueue: any[] = [];

  constructor( private router: Router,
    private spinner: NgxSpinnerService, private messageService: MessageService,) {

  }


  /**
   * @description showOrHideSpinner
   * @param loading
   * @param name
   * !createdBy ehab duraidi
   * !dir ehab duraidi
   */
  showOrHideSpinner(loading: boolean) {
    this.addToSpinnerQueue(loading);
  }
  /**
   * @description addToSpinnerQueue
   * @param loading
   * @param name
   * !createdBy ehab duraidi
   * !dir ehab duraidi
   */
  addToSpinnerQueue(loading: boolean) {
    if (loading) {
      this.spinnerQueue.push(true);
      this.spinner.show("NgCareNet");
    } else {
      var isLoadingSpinnerQueue = this.spinnerQueue.filter(x => x == true);
      if (isLoadingSpinnerQueue.length > 1) {
        var fifoQueue = this.spinnerQueue.findIndex(x => x == true);
        this.spinnerQueue[fifoQueue] = false;
      } else {
        this.spinner.hide("NgCareNet");
        this.spinnerQueue = [];
      }
    }
  }


  pushMessage(type: MessageType, summary: string, messageText: string, showSingleToast = true, sticky = false, life = 1000) {
   
    if (showSingleToast) {
      this.messageService.clear(); 
    }
    this.messageService.add({
      severity: this.getMessagetype(type),
      summary: summary,
      detail: messageText,
      sticky: sticky,
      life: life
    });

  }

  getMessagetype(messageType: MessageType) {
    let type = "";
    switch (messageType) {
      case MessageType.Success: {
        type = "success";
        break;
      }
      case MessageType.Error: {
        type = "error";
        break;
      }
      case MessageType.Info: {
        type = "info";
        break;
      }
      case MessageType.Warn: {
        type = "warn";
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    return type;
  }


}


export enum MessageType {
  Info = 1,
  Success = 2,
  Warn = 3,
  Error = 4
}
