import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms'
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { environment } from '../../environments/environment';


// declare function CallLoginGeneral call from java Script;
declare function CallLoginGeneral(): any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
    grant_type: new FormControl(''),
  });
  formRestPassword = new FormGroup({
    Email: new FormControl('')
  })

  display = false;
  showErrorMsg: boolean= false;
  errorMsg: string = "";
  isRegionalActive: boolean = false;
  jordanClass = "";
  regionalClass = "";
  jordanContentClass = "";
  rejionalContentClass = "";

  constructor(private formBuilder: FormBuilder, private loginService: LoginService, private route: Router ) { }

  ngOnInit(): void {

    CallLoginGeneral();

    //nav-link active
    this.jordanClass = "nav-link active";
    this.regionalClass = "nav-link";

    this.jordanContentClass = "tab-pane active";
    this.rejionalContentClass = "tab-pane";

    if (environment.isRegionalActive) {
      this.isRegionalActive = environment.isRegionalActive;
      this.jordanClass = "nav-link";
      this.jordanContentClass = "tab-pane";

      this.regionalClass = "nav-link active";     
      this.rejionalContentClass = "tab-pane active";
    }


    this.buildForm();
    this.formRestPassword = this.formBuilder.group({
      email: ['', [
        Validators.required,

      ]]
    })
  }

  login(userData: any) {

   
    //this.loginService.loginPatient(userData).subscribe((res: any) => {
    //  if (res) {
    //    this.route.navigate(['home'])
    //  }
    //});


    this.showErrorMsg = false;
    this.errorMsg = "";

    this.loginService.loginPatient(userData).then(     
      (res: any) => {
     
        if (!res.error)
          this.route.navigate(['home']);
        else {
          this.showErrorMsg = true;
          this.errorMsg = res.error.error_description;         
        }      
      });  
  }

  showDialog() {
    this.display = true
  }

  checkPass(email: string) {
    this.loginService.forgotPassword(email).subscribe(res => {
      if (res) {
      }
      this.buildForm();
    })
  }

  buildForm() {
    this.form = this.formBuilder.group({
      username: ['', [
        Validators.required,

      ]],
      password: ['', [
        Validators.required,
      ]],
      grant_type: ['password', [
        Validators.required,
      ]],
    });
  }
  Clear() {
    this.formRestPassword.controls["email"].setValue(null);
  }

  GoToDoctorPortal() {
    window.location.href = environment.doctorPortalURL;   
  }

  GoToCompaniesPortal() {
    window.location.href = environment.companiesPortalURL;
  }

  GoToReferralPortal() {
    window.location.href = environment.referrealPortalURL;
  }

  GoToJordanPortal() {
    window.location.href = environment.jordanURL;
  }

  GoToRejionalPortal() {
    window.location.href = environment.regionalURL;
  }

  GoToPreviousReuslt() {
    window.location.href = environment.previousReusltUrl;
  }
}
