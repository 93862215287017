import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { PatientService } from 'src/app/services/patient.service';
import { VisitService } from 'src/app/services/visit.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { CommonService, MessageType } from '../services/shared/common.service';
import { OrderByPipe } from '../shared/order-by.pipe';
import * as Highcharts from 'highcharts';
import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [OrderByPipe],
})
export class HomeComponent implements OnInit {
  highcharts: typeof Highcharts = Highcharts;
  barOptions: any;
  ChangePasswordForm!: FormGroup;
  showButton: any[] = [];
  hideButton: any[] = [];
  patients: any = []
  patientInfo: any
  username: any = ''
  //  show=false;
  memberDetails: any = {};
  patientVisits: any = []
  labTests: any = []
  labActionsList: any = []
  patientId: number = 0;
  patientName: string = '';
  display = false;
  showFinalizLable = false;
  email: any = '';
  labOrderId: number = 0;
  showLabTest = false;
  preVisits: any = [];
  showPreVisits = false;
  showPreVisitsChart = false;
  basicData: any;
  statuses: any = [];

  rangeDates: Date[] = [];
  selectedStatus: any;
  slideConfig = { slidesToShow: 4, slidesToScroll: 4 };
  showChangePassword: boolean= false;
  showIncorrectPassword: boolean = false;
  showIncorrectConfirmPassword: boolean = false;
  iscollapse: boolean = false;
  basicOptions: any;
  isSingleLine!: boolean;
  preVisitsChart: any = [];
  labTestName: string = '';
  selectedIndex: number = 0;
  unitName: string = '';
  normalRange: any = [];
  element: HTMLElement;
  selectedId: number = 0;
  showNoDataFound: boolean = false;
  normalRangeFormat: any = [];
  addSlide() {
    this.patients.push({ img: 'http://placehold.it/350x150/777777' });
  }
  removeSlide() {
    this.patients.length = this.patients.length - 1;
  }

  slickInit(e: any) {
    console.log('slick initialized');
  }
  breakpoint(e: any) {
    console.log('breakpoint');
  }
  afterChange(e: any) {
    console.log('afterChange');
  }
  beforeChange(e: any) {
    console.log('beforeChange');
  }
  maxResultValue: number = 0;

  constructor(private patientService: PatientService, private visitService: VisitService, private loginService: LoginService
    , private router: Router, private cs: CommonService, private orderByPipe: OrderByPipe) {
    let userName = localStorage.getItem(environment.storageUserName)
    this.username = userName
  }

  ngOnInit(): void {
    this.maxResultValue = 0;
    this.loginService.username.subscribe(u => {
      this.email = u
    });

    this.getMemberInfo(this.username);
    this.getMembersByUserName(this.username);

    this.statuses = [
      { name: 'All', value: -1 },
      { name: 'In Progress', value: 2 },
      { name: 'Finalized', value: 3 },
    ];    
    this.showChangePassword = false;
  }

  getMemberInfo(name: string) { 
    this.patientService.getMemberPatientInfoByUsername(name).subscribe(res => {
      if (res) {
        res = this.orderByPipe.transform(res, "Birthdate", "asc");

        this.patientInfo = res
        this.memberDetails = this.patientInfo[0];
        this.getLabsOrdersByMemberId(this.patientInfo[0].ID, this.patientInfo[0].EnglishName);
      }
    });
  }

  getMembersByUserName(name: string) {
    this.selectedIndex = 0;
    this.patientService.getMembersByUserName(name).subscribe(res => {
      if (res) {
        this.patients = res
      }
    });
  }

  getMemberInfoById(name: string,index:number) {

    this.selectedIndex = index;
    this.patientVisits = [];

    this.patientService.getMemberPatientInfoByUsername(this.username).subscribe(res => {
      if (res) {
        this.patientInfo = res;
        this.patientInfo.forEach((element: any) => {
          if (element.EnglishName === name || element.ArabicName === name) {
            this.memberDetails = element;
            this.getLabsOrdersByMemberId(element.ID, element.EnglishName);
          }
        });
      }
    });
  }

  FillLabTests(id: any) {

    this.showButton  = [];
    this.hideButton  = [];
    this.selectedId = id;
 

    this.showLabTest = true;
    this.labOrderId = id;

    this.showPreVisits = false;
    this.showPreVisitsChart = false;
    this.preVisits = [];

    this.labTests = [];
    this.visitService.getLabTestByLabOrerId(id).subscribe(res => {
      if (res) {
    
        this.showFinalizLable = false;
        this.labTests = res;

      } else {
        this.showFinalizLable = true;
   
      }
    });
 

    this.showButton[id] = true;
    this.hideButton[id] = true;
  }
  toogle() {

    this.iscollapse = this.iscollapse ? false : true;

  }
 
  HideLabTests(id: number) {
    this.selectedId = 0;
    this.showButton[id] = false;
    this.hideButton[id] = false;
 

    this.labTests = [];
  }

  getOnlineLabResultsByOrderId(labId: number) {

    this.visitService.getOnlineLabResults(labId, this.patientName).subscribe(res => {
      if (res) {

        if (res.Message) {
          this.cs.pushMessage(MessageType.Warn, "View Results", res.Message,true,false,10000);
          return;
        }

        window.open(res, '_blank');
      }
    })
  }
  showSendResultPopup(id: number) {
    this.display = true
    this.labOrderId = id

  }

  sendEmail() {
    this.visitService.sendLabResultByNotificationEmail(this.labOrderId, this.patientName).subscribe(res => {
      if (res) {

        if (res.Message) {
          this.cs.pushMessage(MessageType.Warn, "Send Results", res.Message, true, false, 10000);
          this.display = false;
          return;
        }

        this.display = false;
        this.cs.pushMessage(MessageType.Success, "Send Results", "Opertaion Done Successfully.");
      }
    });
  }

  viewResults(labTestId: number) {
    let labtestid = Number(labTestId)
    this.visitService.GetOnlineLabResults(this.labOrderId, this.patientName, labtestid).subscribe(res => {
      if (res) {

        if (res.Message) {
          this.cs.pushMessage(MessageType.Warn, "View Results", res.Message, true, false, 10000);
          return;
        }

        //window.location.href = res
        window.open(res, '_blank');
      }
    });
  }

  async ViewInteractiveResult(labTestId: number, visitId: number, labOrderId: number) {
    await this.visitService.CheckLabFinancialStatus(visitId).subscribe(res => {
      if (res) {
        if (res.Message) {
          this.cs.pushMessage(MessageType.Warn, "Interactive Result", res.Message, true, false, 10000);
          return;
        }
      } else {
        window.open(`interactive-result?v=${visitId}-${labOrderId}-${labTestId}`, '_blank');
      }
    });
  }

  async ViewInteractiveResultOrder(labOrderId: number, visitId: number) {
    await this.visitService.CheckLabFinancialStatus(visitId).subscribe(res => {
      if (res) {
        if (res.Message) {
          this.cs.pushMessage(MessageType.Warn, "Interactive Result", res.Message, true, false, 10000);
          return;
        }
      } else {
        window.open(`interactive-result?v=${visitId}-${labOrderId}`, '_blank');
      }
    });
  }

  viewPreviousResults(labTest: any) {
    this.normalRangeFormat = [];
    this.normalRange = [];
    this.showPreVisits        = false;
    this.showPreVisitsChart   = false;
    let labTestId             = Number(labTest.ID);

    this.visitService.getPreviousResults(this.labOrderId, labTestId, this.patientId).subscribe(res => {
      if (res) {
        this.isSingleLine = res[0].IsSingleLine;
        this.maxResultValue = res[0].maxValue;
        if (this.isSingleLine == false) {
          this.showPreVisits = true;
          this.preVisits = res;
          this.labTestName = labTest.ServiceName;

        }
        else {
          debugger;
          
          this.showPreVisitsChart = true;
          this.preVisitsChart = res;
          this.labTestName = res[0].LabTestName;
          this.unitName = res[0].UnitName;
  

          this.normalRange = res[0].NormalRange;
          this.basicData = {
            labels: this.preVisitsChart.map((stat: { RequestDate: any; }) => stat.RequestDate),
            datasets: [
              {
                label: this.labTestName,
                data: this.preVisitsChart.map((stat: { Value: any; }) => stat.Value),
                tension: .4,
                backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
                borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
                borderWidth: 1
              },
            ],
            options: {
              scales: {
                yAxes: [{
                  ticks: {
                    min: 1,
                    max: 140
                  }
                }]
              }
            }
          };

          this.basicOptions = {
            plugins: {
              legend: {
              }
            },
            scales: {
              y: {
                beginAtZero: false,
                grid: {
                  drawBorder: false
                }
              },
            }
          };



          this.barOptions = {
            chart: {
              type: 'column'
            },
            title: {
              text: this.labTestName,
              align: 'center'
            },
            xAxis: {
              categories: this.preVisitsChart.map((stat: { RequestDate: any; }) => stat.RequestDate),
              title: {
                text: null
              }
            },
            yAxis: {
              min: 1,
              max: this.maxResultValue,
              title: {
                text: ''
              }
            } ,
            plotOptions: {
              column: {
                colorByPoint: true,
                colors: ['#2caffe', '#c8c8c8', '#fff4e6']
              } 
            },
            credits: {
              enabled: false
            },
            series: [
              {
                 
                name: this.labTestName,
                data: this.preVisitsChart.map((stat: { Value: any; }) => stat.Value)
              }
            ]
          };

        }
        // this.showPreVisits=true
        // this.showPreVisitsChart=true


      }
    })
  }
  labWise() {

  }

  SearchVisit(id: any, visitNo: any, referral: any) {   
    this.showNoDataFound = false;
  
    let status = -1;
    if (this.selectedStatus)
      status = this.selectedStatus.value;

    this.patientVisits = [];
    this.visitService.getLabsOrdersByMemberIdWithVisitNo(id, visitNo, referral, this.rangeDates, status).subscribe(res => {
      if (res && res.length > 0) {
        this.patientVisits = res
      } else {
        this.showNoDataFound = true;
      }
    });
  }

  getLabsOrdersByMemberId(id: number, name: string) {
    this.showNoDataFound = false;
    this.patientId = id;
    this.patientName = name;

    this.visitService.getLabsOrdersByMemberId(id).subscribe(res => {
      if (res) {
        this.patientVisits = res;
      } else {
        this.showNoDataFound = true;
      }
    });
  }
  viewResult(labOrderd: number, labTestId: any) {
   
    let labtestid = Number(labTestId);
    this.visitService.GetOnlineLabResults(labOrderd, this.patientName, labtestid).subscribe(res => {
      if (res) {

        if (res.Message) {
          this.cs.pushMessage(MessageType.Warn, "View Results", res.Message, true, false, 10000);
          return;
        }
      
        window.open(res, '_blank');
      }
    });
  }

  onTabOpen(event) {
    if (event.defaultPrevented)
      return false;
 
  }
  onTabClose(event: any) {
     
    if (event) {
      return  
    }
  }

  SignOut() {
    localStorage.clear();
    this.router.navigateByUrl('');    
  }

  buildChangePasswordForm() {

    this.ChangePasswordForm = new FormGroup({
      'ChangePasswordForm_oldPassword': new FormControl(null, [Validators.required]),
      'ChangePasswordForm_newPassword': new FormControl(null, [Validators.required]),
      'ChangePasswordForm_confirmPassword': new FormControl(null, [Validators.required]),
    });
  }

  ChangePassword() {

    this.buildChangePasswordForm();

    this.showIncorrectPassword = false;
    this.showIncorrectConfirmPassword = false;
    this.showChangePassword = true;  
  }

  UpdatePassword() {

    if (this.memberDetails) {
      this.showIncorrectPassword = false;
      this.showIncorrectConfirmPassword = false;

      let oldPassword = this.ChangePasswordForm.controls['ChangePasswordForm_oldPassword'].value;
      let newPassword = this.ChangePasswordForm.controls['ChangePasswordForm_newPassword'].value;
      let confirmPassword = this.ChangePasswordForm.controls['ChangePasswordForm_confirmPassword'].value;

      if (oldPassword != localStorage.getItem(environment.storagePassword)) {
        this.showIncorrectPassword = true;
        return;
      }

      if (newPassword != confirmPassword) {
        this.showIncorrectConfirmPassword = true;
        return;
      }

      this.loginService.ChangePassword(this.memberDetails.UserName, newPassword).subscribe((res: any) => {
        if (!res.Error) {
          this.cs.pushMessage(MessageType.Success, "Change Password", "Opertaion Done Successfully.");
          this.router.navigateByUrl('');
        }
      });   
     
    }
  }
}
