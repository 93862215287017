import { Component, OnInit } from '@angular/core';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-lab-results',
  templateUrl: './lab-results.component.html',
  styleUrls: ['./lab-results.component.scss']
})
export class LabResultsComponent implements OnInit {
  patients:any=[]
  patientInfo:any
  username:any=''
   show=false;
  memberDetails: any={};
  constructor(private patientService:PatientService) { 
    let userName=localStorage.getItem("UserName")
    this.username=userName
  }

  ngOnInit(): void {
    

    this.getMemberInfo(this.username)
    this.getMembersByUserName(this.username)
    // this.patients=[{fileNo:201600117,patientName:'Issam Hasan Alhinidy'},
    // {fileNo:201900164,patientName:'Ameer Karim Alhinidy'},
    // {fileNo:201400178,patientName:'Jameela Hasan Alhinidy'}]
  }

getMemberInfo(name:string){
  this.patientService.getMemberPatientInfoByUsername(name).subscribe(res=>{
    if (res) {
      this.patientInfo=res
       this.memberDetails = this.patientInfo[0];
    }
  })
}

getMembersByUserName(name:string){
  this.patientService.getMembersByUserName(name).subscribe(res=>{
    if (res) {
      this.patients=res
    }
  })
}

getMemberInfoById(name:string){
  this.patientService.getMemberPatientInfoByUsername(this.username).subscribe(res=>{
    if (res) {
      this.patientInfo=res
      this.patientInfo.forEach((element:any) => {
        if (element.EnglishName===name || element.ArabicName===name) {
          this.memberDetails = element;
        }
      });
    }
  })
}
}
