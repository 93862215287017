import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenHandlerService } from './shared/token-handler.service';
import { tap, delay, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root' 
})
export class LoginService {
  readonly Token = environment.storageToken;
  readonly userName = environment.storageUserName;
  readonly password = environment.storagePassword;

   tokenSubject!: BehaviorSubject<string | null>;
  public token!: Observable<string | null>;

  userNameSubject!: BehaviorSubject<string | null>;
  public username!: Observable<string | null>;

  private environment;
  private url;

  private httpHeaders = new HttpHeaders() 
  //.set('Access-Control-Allow-Headers','Origin, X-Requested-With, Content-Type, Accept, Authorization')
    .set('Authorization', 'bearer ' + localStorage.getItem("MedlUToken"))

 private _httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Cache-Enabled', 'true').set('Accept', '*/*').set('content-type', 'application/json').set('Access-Control-Allow-Headers', "Origin, X-Requested-With, Content-Type, Accept, Authorization");

  constructor(private httpClient: HttpClient,
    private tokenhandler: TokenHandlerService  ) {
    this.environment = environment;
    this.url = this.environment.url;

    let initToken = localStorage.getItem(this.Token);
    this.tokenSubject = new BehaviorSubject<string | null>(initToken);
    this.token = this.tokenSubject.asObservable();

    let inituserName = localStorage.getItem(this.userName);
    this.userNameSubject = new BehaviorSubject<string | null>(inituserName);
    this.username = this.userNameSubject.asObservable();
  }

   // /api/Security/Login
  //loginPatient(userAuthenticate: any) {
   
  //  const body = new HttpParams()
  //  .set('username', userAuthenticate.username)
  //  .set('password',  userAuthenticate.password)
  //  .set('grant_type', 'password');
    
  //  return this.httpClient.post(`${this.url}/Token`,
  //    body.toString(),
  //    {
  //      headers: new HttpHeaders()
  //        .set('Accept', 'text/plain')
  //        .set("Content-Type", "application/x-www-form-urlencoded; charset=utf-8")
  //      //.set('Access-Control-Allow-Origin', '*' )
  //    }
  //  ).pipe(map((restokn: any) => {
       
  //      localStorage.setItem(this.Token, restokn.access_token);
  //      this.tokenSubject.next(restokn.access_token);

  //      localStorage.setItem(this.userName, restokn.userName);
  //      this.userNameSubject.next(restokn.userName);

  //      localStorage.setItem(this.password, userAuthenticate.password);

  //      return restokn;
  //    })); 
  //}

   
  loginPatient(userAuthenticate: any) {

    const body = new HttpParams()
      .set('username', userAuthenticate.username.trim())
      .set('password', userAuthenticate.password)
      .set('grant_type', 'password');

    return this.httpClient.post(`${this.url}/Token`,
      body.toString(), {
      headers: new HttpHeaders()
        .set('Accept', 'text/plain')
        .set("Content-Type", "application/x-www-form-urlencoded; charset=utf-8")
      //.set('Access-Control-Allow-Origin', '*' ) 
    }).toPromise().then
      ((response: any) => {
        localStorage.setItem(this.Token, response.access_token);
        this.tokenSubject.next(response.access_token);
        this.tokenhandler.setTokenExpiredate(response.expires_in);
        localStorage.setItem(this.userName, response.userName);
        this.userNameSubject.next(response.userName);

        localStorage.setItem(this.password, userAuthenticate.password);

        return response;
      }
    ).catch((error: any) =>
      {
        return error
      });
  } 

  forgotPassword(username:string){
    return this.httpClient.get<any>(`${this.url}/api/member/ForgotPassword?email=${username}`,{headers:this.httpHeaders} )
   
  }

  ChangePassword(memberEmail: string, newPassword: string): Observable<any> {
    let data = {     
      'memberEmail': memberEmail,
      'newPassword': newPassword,      
    };
      let _httpHeaders = new HttpHeaders()
    
    .set('Authorization', 'bearer ' + localStorage.getItem("MedlUToken"))

    //For HTTPPost
    const url = `${this.url}/api/member/ChangePassword`;
    //const url = `http://localhost:3333/api/member/ChangePassword`;
    return this.httpClient.post(url, data, { headers: _httpHeaders });
  }

  public get currentTokenSubjectValue():string|null
  {
    return this.tokenSubject.value;
  }


}



