<!-- begin:: Page -->
<div class="kt-grid kt-grid--ver kt-grid--root">
  <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login">

    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" style="background-image: url(assets/medlabs-media/bg.png);">
      <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
        <div class="kt-login__container">
          <div class="kt-login__logo">
            <a href="#">
              <img src="assets/medlabs-media/logo.png" width="200">
            </a>
          </div>
          <div class="kt-login__head">
            <h3 class="kt-login__title">
              Online Results
              نتائج الفحوصات
            </h3>
          </div>
          <div class="kt-portlet__body">
            <ul class="nav nav-pills nav-fill" role="tablist">
              <li class="nav-item">
                <a [class]="jordanClass" data-toggle="tab" href="#kt_tabs_5_1" (click)="GoToJordanPortal()"><span>Jordan / الأردن</span></a>
              </li>
              <li class="nav-item">
                <a [class]="regionalClass" data-toggle="tab" href="#kt_tabs_5_2" (click)="GoToRejionalPortal()">
                  <span>Iraq / Palestine</span>
                  <span>
                    العراق \ فلسطين
                  </span>
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div [class]="jordanContentClass" id="kt_tabs_5_1" role="tabpanel">
                <div class="kt-login__signin">
                  <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="showErrorMsg">
                    <div class="alert-text">{{errorMsg}}</div>
                  </div>
                  <form class="kt-form" [formGroup]="form">
                    <div class="input-group">
                      <input class="form-control" type="text" placeholder="Email / البريد الالكتروني" name="email"
                             autocomplete="off" formControlName="username">
                    </div>
                    <div class="input-group">
                      <input class="form-control" type="password" placeholder="Password /كلمة السر" name="password" formControlName="password">
                    </div>
                    <div class="row kt-login__extra">

                      <div class="col kt-align-center">
                        <a href="javascript:;" id="kt_login_forgot" class="kt-login__link medlabs_color">Forgot your password? / نسيت كلمة السر؟</a>
                      </div>
                    </div>

                    <div class="kt-login__actions">
                      <button type="submit" id="kt_login_signin_submit" class="btn btn-brand btn-primary kt-login__btn-primary sign-in-btn"
                              (click)="login(form.value)" [disabled]="!form.valid">
                        Sign In
                      </button>
                    </div>
                    <br />
                    <div class="row" *ngIf="!isRegionalActive">
                      <div class="col kt-align-center">

                        <a href="javascript:;" (click)="GoToPreviousReuslt()" class="kt-login__link medlabs_color"> النتائج قبل 01/10/2017 اضغط هنا/Result before 01/10/2017 click here </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div [class]="rejionalContentClass" id="kt_tabs_5_2" role="tabpanel">
                <div class="kt-login__signin">

                  <div class="alert alert-danger alert-dismissible" role="alert" *ngIf="showErrorMsg">
                    <div class="alert-text">{{errorMsg}}</div>
                  </div>
                  <form class="kt-form" [formGroup]="form">
                    <div class="input-group">
                      <input class="form-control" type="text" placeholder="Email / البريد الالكتروني" name="email"
                             autocomplete="off" formControlName="username">
                    </div>
                    <div class="input-group">
                      <input class="form-control" type="password" placeholder="Password /كلمة السر" name="password" formControlName="password">
                    </div>
                    <div class="row kt-login__extra">

                      <div class="col kt-align-center">
                        <a href="javascript:;" id="kt_login_forgot2" class="kt-login__link medlabs_color">Forgot your password? / نسيت كلمة السر؟</a>
                      </div>
                    </div>
                    <div class="kt-login__actions">
                      <button type="submit" id="kt_login_signin_submit" class="btn btn-brand btn-primary kt-login__btn-primary sign-in-btn"
                              (click)="login(form.value)" [disabled]="!form.valid">
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="kt-login__forgot">
              <div class="kt-login__head">
                <h3 class="kt-login__title">Forgot your password? / هل نسيت كلمة المرور؟</h3>
                <div class="kt-login__desc"> ادخل البريد الالكتروني لاعادة تعيين كلمة المرور </div>
              </div>
              <form class="kt-form" [formGroup]="formRestPassword">
                <div class="input-group">
                  <input class="form-control" type="text" placeholder="Email / البريد الالكتروني" formControlName="email"
                         id="kt_email" autocomplete="off" #input>
                </div>
                <div class="kt-login__actions">
                  <button id="kt_login_forgot_submit"
                          class="btn btn-brand btn-success kt-login__btn-primary" [disabled]="!formRestPassword.valid" (click)="checkPass(input.value)">
                    Request
                  </button>&nbsp;&nbsp;
                  <button id="kt_login_forgot_cancel"
                          class="btn  btn-danger kt-login__btn-secondary" (click)="Clear()">
                    Cancel
                  </button>
                </div>
              </form>
            </div>

          </div>

     

        </div>
        <div class="portals-buttons">
          <button id="kt_login_signin_submit" (click)="GoToDoctorPortal()"
                  class="btn btn-brand btn-primary kt-login__btn-primary">
            Doctors Portal
          </button>
          <button id="kt_login_signin_submit" (click)="GoToCompaniesPortal()"
                  class="btn btn-brand btn-primary kt-login__btn-primary">
            Companies Portal
          </button>
          <button id="kt_login_signin_submit" (click)="GoToReferralPortal()"
                  class="btn btn-brand btn-primary kt-login__btn-primary">
            Referral Portal
          </button>
        </div>

      </div>
    </div>
  </div>
</div>

 




