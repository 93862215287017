export const environment = {
  storageToken: 'MedlUToken',
  storageUserName: 'UserName',
  storagePassword: 'Password',
  showSignature: true,
  isArabicName: false,
  branchId: 1,
  companyId: 1,
  production: true,
  /* Live API*/
  url: 'https://online.medlabsgroup.com/ResultsAPI',
  isRegionalActive: false,
  doctorPortalURL: "http://213.186.160.67:555/Login/FrmLogin.aspx?urlParm=DP",
  companiesPortalURL: "http://213.186.160.67:555/Login/FrmLogin.aspx?urlParm=PP",
  referrealPortalURL: "http://213.186.160.67:555/Login/FrmLogin.aspx?urlParm=RP",
  jordanURL: "https://online.medlabsgroup.com/",
  regionalURL: "http://regional.medlabsresults.com/",
  previousReusltUrl: "http://213.186.160.67:8086/index.aspx"

};
